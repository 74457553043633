import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Input,
  Label,
  ActionStyledAsButton,
  Avatar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { isOptionalMail } from 'utils/isMail';
import { dataTestIds } from 'utils/dataTestIds';

export default function EditUserDialog({
  isLoadingUpdateUser,
  isOpen,
  selectedUser,
  setOpenModalEditUser,
  setSelectedUser,
  updateUser,
}) {
  const t = useFormatMessage();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onDissmis = useCallback(() => {
    setOpenModalEditUser(false);
    setSelectedUser(null);
    reset();
  }, [reset, setOpenModalEditUser, setSelectedUser]);

  const onSubmit = useCallback(
    (data) => {
      if (data) {
        updateUser(data, onDissmis);
      }
    },
    [onDissmis, updateUser],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDissmis}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader
          otherButton={<Avatar avatar={selectedUser?.avatar} className="h-8" />}
        >
          {t('global.edit_user')}
        </DialogHeader>
        <DialogContent className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <Label htmlFor="editUsersDialog-firstName">
              {t('profile.first_name')}
            </Label>
            <Controller
              control={control}
              defaultValue={selectedUser?.firstName}
              name="firstName"
              render={({ field: { onBlur, onChange, value } }) => (
                <Input
                  data-test={dataTestIds.modal.editUser.input.firstName}
                  hasErrors={Boolean(errors.firstName)}
                  id="editUsersDialog-firstName"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t('global.required-field'),
                },
              }}
              type="input"
            />
            {errors.firstName && (
              <span className="text-red-500 text-xs">
                {errors.firstName.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <Label htmlFor="editUsersDialog-lastName">
              {t('profile.last_name')}
            </Label>
            <Controller
              control={control}
              defaultValue={selectedUser?.lastName}
              name="lastName"
              render={({ field: { onBlur, onChange, value } }) => (
                <Input
                  data-test={dataTestIds.modal.editUser.input.lastName}
                  hasErrors={Boolean(errors.lastName)}
                  id="editUsersDialog-lastName"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t('global.required-field'),
                },
              }}
              type="input"
            />
            {errors.lastName && (
              <span className="text-red-500 text-xs">
                {errors.lastName.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <Label htmlFor="editUsersDialog-email">{t('global.email')}</Label>
            <Controller
              control={control}
              defaultValue={selectedUser?.email}
              name="email"
              render={({ field: { onBlur, onChange, value } }) => (
                <Input
                  data-test={dataTestIds.modal.editUser.input.email}
                  hasErrors={Boolean(errors.email)}
                  id="editUsersDialog-email"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
              rules={{
                validate: {
                  isOptionalMail,
                },
              }}
              type="input"
            />

            {errors.email?.type === 'isMail' && (
              <span className="text-red-500 text-xs">
                {t('email.validate-error')}
              </span>
            )}
            {errors.email && (
              <span className="text-red-500 text-xs">
                {errors.email.message}
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            disabled={isLoadingUpdateUser}
            onClick={onDissmis}
            test={dataTestIds.modal.editUser.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={Object.keys(errors).length > 0 || isLoadingUpdateUser}
            loading={isLoadingUpdateUser}
            test={dataTestIds.modal.editUser.button.submit}
            type="submit"
          >
            {t('global.edit_user')}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
