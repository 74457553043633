import { SchooladminTaskInfoTable } from 'components/Tables/SchooladminTaskInfoTable';
import { useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ActionStyledAsButton, Dialog } from '@ftrprf/tailwind-components';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import useFormatMessage from 'hooks/useFormatMessage';

export function SmartschoolStatus() {
  const t = useFormatMessage();
  const { importData, isLoading, refetchTaskInfo } = useOutletContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  console.log({ isLoading });

  useEffect(() => {
    refetchTaskInfo();
    // do this when the page loads as the user might have gone to the other tab first
    // if we don't do this on page load we miss out on any changes from that
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ActionStyledAsButton
        className="mb-4"
        disabled={isLoading}
        iconBefore={ArrowPathIcon}
        loading={isLoading}
        onClick={refetchTaskInfo}
      >
        {t('global.reload')}
      </ActionStyledAsButton>
      <SchooladminTaskInfoTable
        data={importData}
        isLoading={isLoading}
        setDialogContent={setDialogContent}
        setIsDialogOpen={setIsDialogOpen}
      />
      <Dialog
        className="min-w-fit"
        isOpen={isDialogOpen}
        onDismiss={() => setIsDialogOpen(false)}
      >
        {dialogContent}
      </Dialog>
    </div>
  );
}
