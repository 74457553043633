import { gql } from 'graphql-request';

export const ADMIN_ORGANIZATIONS = gql`
  query adminOrganizations(
    $filter: [JSON]
    $page: Int!
    $size: Int!
    $sort: JSON
  ) {
    adminOrganizations(filter: $filter, page: $page, size: $size, sort: $sort) {
      total
      pages
      currentPage
      content {
        id
        name
        prefix
        language
        issuer
        integrationInfo {
          __typename
          ... on SmartSchoolInfo {
            smartSchoolDomain
            importStudents
            importTeachers
            importClasses
            importMembershipOfStudentsToClasses
            importMembershipOfTeachersToClasses
            importOneRosterStudents
            importOneRosterTeachers
            oneRosterCredentials {
              clientId
              clientSecret
            }
          }
          ... on TeamsInfo {
            tenantId
          }
        }
        active
        beta
        demo
        demoEndDate
        steams
        showCalendar
      }
    }
  }
`;
