import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import {
  getHour,
  getMinutesRoundedDownToFiveFormItem,
} from 'utils/timeHelpers';
import useFormatMessage from 'hooks/useFormatMessage';

export function EndTime({
  control,
  errors,
  itemInfo,
  timeOptionHour,
  timeOptionMinute,
  today,
  validateEndTime,
}) {
  const t = useFormatMessage();

  return (
    <div className="grow">
      <p className="mb-1">{t('calendar.modal.endTime')}</p>
      <Label className="sr-only" htmlFor="formCalendarItem_endTimeHour">
        {t('calendar.modal.endHour')}
      </Label>
      <div className="flex items-start">
        <div className="w-full">
          <Controller
            control={control}
            defaultValue={getHour(itemInfo, today, 1)}
            name="endTimeHour"
            render={({ field: { onBlur, onChange, value } }) => (
              <Dropdown
                hasErrors={Boolean(errors.endTimeHour?.type === 'required')}
                inputId="formCalendarItem_endTimeHour"
                isMulti={false}
                onBlur={onBlur}
                onChange={onChange}
                options={timeOptionHour}
                placeholder="HH"
                returnMethod="object"
                value={value?.value}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('dropdown.no_results'),
              },
              validateEndTime,
            }}
            type="select"
          />
          {errors.endTimeHour?.type === 'required' && (
            <p className="text-xs text-red-600">{errors.endTimeHour.message}</p>
          )}
        </div>

        <p className="mx-2">:</p>
        <Label className="sr-only" htmlFor="formCalendarItem_endTimeMinute">
          {t('calendar.modal.endMinute')}
        </Label>
        <div className="w-full">
          <Controller
            control={control}
            defaultValue={getMinutesRoundedDownToFiveFormItem(itemInfo, today)}
            name="endTimeMinute"
            render={({ field: { onBlur, onChange, value } }) => (
              <Dropdown
                hasErrors={Boolean(errors.endTimeMinute)}
                inputId="formCalendarItem_endTimeMinute"
                isMulti={false}
                onBlur={onBlur}
                onChange={onChange}
                options={timeOptionMinute}
                placeholder="MM"
                returnMethod="object"
                value={value?.value}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('dropdown.no_results'),
              },
            }}
            type="select"
          />
          {errors.endTimeMinute && (
            <p className="text-xs text-red-600">
              {errors.endTimeMinute.message}
            </p>
          )}
        </div>
      </div>
      {errors.endTimeHour?.type === 'validate' && (
        <p className="text-xs text-red-600">
          {t('calendar.modal.startTime<endTime')}
        </p>
      )}
    </div>
  );
}
