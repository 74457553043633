import { generatePath } from 'react-router-dom';
import URLS from 'utils/constants/urls';
import { OWN_CONTENT } from 'utils/constants/contentTypes';
import viewModes from 'utils/constants/viewModes';
import { getStudioIdForLesson } from '../../Calendar/getStudioIdForLesson';

export function postResponse(createdCalendarItem, teacherPrograms, watch) {
  if (createdCalendarItem && teacherPrograms) {
    const { chapter, chapterItem, program } = watch();
    const { teacherCreateCalendarItem } = createdCalendarItem;
    (() => {
      window.opener.postMessage(
        [
          {
            title: chapterItem.label,
            url:
              window.location.origin +
              generatePath(URLS.SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID, {
                studioId:
                  program?.value === OWN_CONTENT
                    ? chapterItem?.studioId
                    : getStudioIdForLesson(
                        teacherPrograms,
                        program?.value,
                        chapter?.value,
                        chapterItem?.value,
                      ),
                sessionId: teacherCreateCalendarItem.sessionId,
                viewMode:
                  teacherCreateCalendarItem.viewMode ?? viewModes.CLASSICAL,
              }),
          },
        ],
        '*',
      );
      window.close();
    })();
  }
}
