/* eslint-disable complexity */
import { useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from 'providers/UserProvider';

import useTeacherOrganizationContent from 'hooks/api/plannerService/teacher/queries/useTeacherOrganizationContent';
import { filterOperation } from 'utils/constants/filter';
import viewModes from 'utils/constants/viewModes';
import { lessonTypes } from 'utils/constants/lessonTypes';
import contentTypes from 'utils/constants/contentTypes';
import { LessonInfo } from './partials/LessonInfo';
import { SelectClass } from './partials/SelectClass';
import { TeamsInfo } from './partials/TeamsInfo';
import { TimeInfo } from './partials/TimeInfo';
import { UpdateChapterItem } from './partials/UpdateChapterItem';
import { ViewMode } from './partials/ViewMode';
import { getChapterItemsForProgram } from './utils/getChapterItemsForChapter';
import { getChaptersForProgram } from './utils/getChaptersForProgram';
import { getLessonsForChapter } from './utils/getLessonsForChapter';
import { validateEndTimeHours } from './utils/validateEndTimeHours';

export default function FormCalendarItem({
  classGroups,
  formOption,
  itemInfo,
  teacherPrograms,
  teamsInfo,
  updateItemOption,
}) {
  const [boxUpdateChapterItem, setBoxUpdateChapterItem] = useState(false);
  const [isExam, setIsExam] = useState(
    itemInfo?.selectedEvent?.lessonContentType === contentTypes.EXAM || false,
  );
  const { control, errors, getValues, setValue, watch } = formOption;
  const { organization } = useContext(UserContext);
  const { data: organizationContent } = useTeacherOrganizationContent({
    filter: [
      {
        key: 'organizationId',
        value: `${organization.id}`,
        operation: filterOperation.EQUAL,
      },
    ],
    page: 0,
    size: 50,
  });
  const ownContent = organizationContent?.content;
  const { isUpdateMode } = updateItemOption;
  const watcherProgram = watch('program');
  const watcherChapter = watch('chapter');
  const watcherChapterItem = watch('chapterItem');
  const watcherStartTimeHour = watch('startTimeHour');
  const watcherStartTimeMinute = watch('startTimeMinute');
  const watcherEndTimeHour = watch('endTimeHour');
  const watcherEndTimeMinute = watch('endTimeMinute');

  const findChapters = useCallback(
    (programId) => getChaptersForProgram(teacherPrograms, programId),
    [teacherPrograms],
  );

  const findChapterItems = useCallback(
    (chapterId) =>
      getChapterItemsForProgram(watcherProgram?.value, findChapters, chapterId),
    [findChapters, watcherProgram?.value],
  );

  const findLessons = useCallback(
    (chapterItemId) =>
      getLessonsForChapter(
        watcherChapter?.value,
        findChapterItems,
        chapterItemId,
      ),
    [findChapterItems, watcherChapter?.value],
  );

  const validateEndTime = () =>
    validateEndTimeHours(
      watcherEndTimeHour,
      watcherEndTimeMinute,
      watcherStartTimeHour,
      watcherStartTimeMinute,
    );

  useEffect(() => {
    if (
      watcherChapterItem &&
      findLessons(watcherChapterItem.value)?.length === 1
    ) {
      const lesson = findLessons(watcherChapterItem.value)[0];
      const isExam = lesson.type === lessonTypes.EXAM;
      if (isExam) {
        setValue('viewMode', viewModes.CLASSICAL);
      }
      setIsExam(isExam);
      setValue('studioId', lesson.studioId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watcherChapterItem]);

  return (
    <form id="CalendarItem">
      <TimeInfo
        formOption={formOption}
        getValues={getValues}
        itemInfo={itemInfo}
        validateEndTime={validateEndTime}
      />

      <SelectClass
        classGroups={classGroups}
        formOption={formOption}
        itemInfo={itemInfo}
      />

      {isExam || <ViewMode control={control} errors={errors} />}

      {isUpdateMode && (
        <UpdateChapterItem
          boxUpdateChapterItem={boxUpdateChapterItem}
          formOption={formOption}
          setBoxUpdateChapterItem={setBoxUpdateChapterItem}
        />
      )}

      {!itemInfo?.chapterItem &&
        (!isUpdateMode || boxUpdateChapterItem) &&
        teacherPrograms && (
          <LessonInfo
            findChapterItems={findChapterItems}
            findChapters={findChapters}
            findLessons={findLessons}
            formOption={formOption}
            itemInfo={itemInfo}
            ownContent={ownContent}
            setIsExam={setIsExam}
            teacherPrograms={teacherPrograms}
          />
        )}

      {teamsInfo.isTeams && (
        <TeamsInfo formOption={formOption} teamsInfo={teamsInfo} />
      )}
    </form>
  );
}
