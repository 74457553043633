import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { GoGear } from 'react-icons/go';
import { generatePath } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/24/solid';

import {
  Actions,
  ActionStyledAsButton,
  Avatar,
  Badge,
  Content,
  Filter,
  FormGroup,
  Input,
  InputGroup,
  Label,
  PageHeader,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';
import useTitle from 'hooks/useTitle';

import { filterOperation } from 'utils/constants/filter';
import URLS from 'utils/constants/urls';
import ftrprfUserRoles from '../../utils/constants/ftrprfUserRoles';

import ClassGroupProfileSkeleton from './partials/ClassGroupProfileSkeleton';
import { ClassPager } from './partials/ClassPager';
import ConfigureUsersModal from './ConfigureUsersModal';

const availableRoles = [
  ftrprfUserRoles.SCHOOLTEACHER,
  ftrprfUserRoles.SCHOOLSTUDENT,
];

export default function ClassProfile({
  canDelete,
  changeClassGroup,
  classGroup,
  classGroups,
  currentPage,
  filter,
  isLoading,
  itemsPerPage,
  pages,
  refetchUserOfClassgroups,
  setFilter,
  setItemsPerPage,
  setPage,
  totalItems,
  users,
}) {
  const [isOpenConfigureUsersModal, setIsOpenConfigureUsersModal] =
    useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const { classId } = useParams();

  const t = useFormatMessage();
  useTitle(classGroup?.name);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm();

  const displayRoleFn = (role) => t(`global.${role}.singular`);

  const routeToUserDetails = ({ id: userId }) =>
    generatePath(URLS.USER_PROFILE_VIA_CLASS, {
      userId,
      classId,
      className: classGroup?.name,
    });

  const actionsForUser = (user) => [
    {
      icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.view'),
      href: routeToUserDetails(user),
    },
  ];

  const onSubmit = useCallback(
    (data) => {
      changeClassGroup(data.name);
      reset(data);
    },
    [changeClassGroup, reset],
  );

  const updateFilter = useCallback(
    (value, field) => {
      let filterUpdated = false;
      filter.forEach((item) => {
        if (item.key === field) {
          // eslint-disable-next-line no-param-reassign
          item.value = value;
          filterUpdated = true;
        }
      });

      if (!filterUpdated)
        filter.push({
          key: field,
          value,
          operation: filterOperation.LIKE,
        });

      setFilter([...filter]);
    },
    [filter, setFilter],
  );

  const changeRoleFilterHandler = useCallback(
    (value) => {
      updateFilter(value, 'roles');
    },
    [updateFilter],
  );

  const breadcrumbsLinks = [
    {
      href: generatePath(URLS.MANAGE_TAB, { tab: 'classes' }),
      name: t('global.CLASS.plural'),
    },
    {
      href: generatePath(URLS.MANAGE_CLASS_DETAILS, {
        classId,
      }),
      name: classGroup?.name,
      current: true,
    },
  ];

  return (
    <>
      <ConfigureUsersModal
        classGroup={classGroup}
        isDisableButton={isDisableButton}
        isOpenConfigureUsersModal={isOpenConfigureUsersModal}
        refetchUserOfClassgroups={refetchUserOfClassgroups}
        setIsDisableButton={setIsDisableButton}
        setIsOpenConfigureUsersModal={setIsOpenConfigureUsersModal}
      />
      <ClassGroupProfileSkeleton canDelete={canDelete} isLoading={isLoading}>
        <PageHeader breadcrumbs={breadcrumbsLinks} title={classGroup?.name}>
          {classGroups && (
            <ClassPager classGroups={classGroups} currentClass={classGroup} />
          )}
        </PageHeader>

        <Content>
          <div className="w-full">
            <form
              className="w-full"
              id="classGroupName"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormGroup hasBorder={false} hasPadding={false}>
                <h1 className="text-3xl font-bold leading-none mb-4">
                  {t('class-group.details')}
                </h1>
                <InputGroup className="w-48">
                  <Label htmlFor="classprofile-classname">
                    {t('class-group.name')}
                  </Label>
                  <Controller
                    control={control}
                    defaultValue={classGroup?.name}
                    name="name"
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Input
                        hasErrors={Boolean(errors.name)}
                        id="classprofile-classname"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: t('dropdown.no_results'),
                      },
                      validate: (value) =>
                        value !== classGroup?.name ||
                        t('class-group.same-name.error'),
                    }}
                    type="text"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-xs">
                      {errors.name.message}
                    </span>
                  )}
                </InputGroup>
                <div className="flex flex-wrap gap-y-4 sm:space-x-2 flex-col sm:flex-row lg:self-end lg:justify-end w-auto">
                  <ActionStyledAsButton
                    className="whitespace-nowrap text-sm flex-grow sm:flex-grow-0"
                    disabled={!isDirty}
                    form="classGroupName"
                    iconBefore={FaSave}
                    type="submit"
                  >
                    {t('global.save-changes')}
                  </ActionStyledAsButton>
                </div>
              </FormGroup>
            </form>

            <div className="w-full">
              <div className="mb-2">
                <h1 className="text-3xl font-bold leading-none mb-4">
                  {t('class-group.linked')}
                </h1>
                <div className="flex">
                  <div className="w-1/4 mr-5">
                    <SearchBar
                      onChange={(value) => updateFilter(value, 'firstname')}
                      placeholder={t('users-overview.firstName')}
                    />
                  </div>
                  <div className="w-1/4 mr-5">
                    <SearchBar
                      onChange={(value) => updateFilter(value, 'lastname')}
                      placeholder={t('users-overview.lastName')}
                    />
                  </div>
                  <div className="mr-5 flex-grow">
                    <Filter
                      activeFilters={
                        filter.find((item) => item.key === 'roles')?.value ||
                        availableRoles
                      }
                      displayOptionFn={displayRoleFn}
                      name={t('global.role')}
                      options={availableRoles}
                      setFilter={changeRoleFilterHandler}
                    />
                  </div>

                  <ActionStyledAsButton
                    className="h-3/4"
                    iconBefore={GoGear}
                    onClick={() => setIsOpenConfigureUsersModal(true)}
                    secondary
                  >
                    {t('global.configure_users')}
                  </ActionStyledAsButton>
                </div>
              </div>
              <ResponsiveTable
                headers={[
                  {
                    key: 'name',
                    label: t('student-overview.column.name'),
                    orderable: false,
                  },
                  {
                    key: 'username',
                    label: t('student-overview.column.username'),
                    orderable: false,
                  },
                  {
                    key: 'role',
                    label: t('global.role'),
                    orderable: false,
                  },
                  {
                    key: 'actions',
                    label: t('global.actions'),
                    orderable: false,
                    className: 'max-w-fit sr-only',
                  },
                ]}
                mobileBreakpoint={768}
                mobileHeaderWidth={160}
                onRowClick={routeToUserDetails}
              >
                {users.map((user) => (
                  <ResponsiveTableRow key={user.id} data={user}>
                    <ResponsiveTableCol>
                      <div
                        className="flex gap-x-4 items-center"
                        title={user.username}
                      >
                        <Avatar avatar={user.avatar} />
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                    </ResponsiveTableCol>
                    <ResponsiveTableCol>{user.userName}</ResponsiveTableCol>
                    <ResponsiveTableCol>
                      {user.roles.map((role) => (
                        <Badge
                          key={role}
                          danger={role.includes('ADMIN')}
                          success={role.includes('STUDENT')}
                          warning={role.includes('TEACHER')}
                        >
                          {displayRoleFn(role)}
                        </Badge>
                      ))}
                    </ResponsiveTableCol>
                    <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                      <Actions items={actionsForUser(user)} />
                    </ResponsiveTableCol>
                  </ResponsiveTableRow>
                ))}
              </ResponsiveTable>

              {Boolean(users?.length) && (
                <Pagination
                  className="mt-12"
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={(page) => setPage(page)}
                  setItemsPerPage={setItemsPerPage}
                  totalItems={totalItems}
                  totalPages={pages}
                />
              )}
            </div>
          </div>
        </Content>
      </ClassGroupProfileSkeleton>
    </>
  );
}
