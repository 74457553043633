export const errorKeys = {
  ACCESS_TOKEN_NOT_FOUND: 'ACCESS_TOKEN_NOT_FOUND',
  EXCEL_IMPORT_GENERAL: 'EXCEL_IMPORT_GENERAL',
  EXCEL_TOO_MANY_USERS: 'EXCEL_TOO_MANY_USERS',
  MICROSOFT_GRAPH_CALL_FAILED: 'MICROSOFT_GRAPH_CALL_FAILED',
  TEAMS_ACCESS_TOKEN_NOT_FOUND: 'TEAMS_EXCEPTION_ACCESS_TOKEN_NOT_FOUND',
  TEAMS_ACCESS_TOKEN_NOT_FOUND_TEMP: 'no valid teams access token',
  EXTERNAL_SYNC_GENERAL: 'EXTERNAL_SYNC_GENERAL',
  EXTERNAL_SYNC_USER_CREATION_FAILED: 'EXTERNAL_SYNC_USER_CREATION_FAILED',
  EXTERNAL_USER_MISSING_FIRST_AND_LAST_NAME:
    'EXTERNAL_USER_MISSING_FIRST_AND_LAST_NAME',
  EXTERNAL_USER_MISSING_FIRST_NAME: 'EXTERNAL_USER_MISSING_FIRST_NAME',
  EXTERNAL_USER_MISSING_LAST_NAME: 'EXTERNAL_USER_MISSING_LAST_NAME',
};
