import useFormatMessage from 'hooks/useFormatMessage';
import { viewModesWithTranslations } from 'utils/constants/viewModes';
import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';

export function SelectDisplay({ formOptions, hidden }) {
  const t = useFormatMessage();
  const { control, errors, watch } = formOptions;
  const { chapterItem } = watch();

  if (hidden) return null;

  const viewModesOptions = [
    // first option is default value
    viewModesWithTranslations.CLASSICAL,
    viewModesWithTranslations.SELFSTUDY,
  ].map((viewMode) => ({
    value: viewMode.value,
    label: t(`${viewMode.label}`),
  }));

  return (
    <>
      <Label className="mt-2" htmlFor="formCalendarItem_selectViewMode">
        {t('calendar.modal.selectViewMode')}
      </Label>
      <div>
        <Controller
          control={control}
          name="viewMode"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              defaultValue={viewModesOptions[0]}
              disabled={chapterItem.isExam}
              inputId="formCalendarItem_selectViewMode"
              onBlur={onBlur}
              onChange={onChange}
              options={viewModesOptions}
              placeholder={t('calendar.modal.selectViewMode')}
              returnMethod="object"
              value={value}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('dropdown.no_results'),
            },
          }}
          type="select"
        />
        {errors.viewMode && (
          <p className="text-xs text-red-600">{errors.viewMode.message}</p>
        )}
        {chapterItem.isExam && (
          <p className="mt-4">{t('smartschool.popup.selectViewMode.exam')}</p>
        )}
      </div>
    </>
  );
}
