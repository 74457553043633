import 'react-datepicker/dist/react-datepicker.css';

import { useContext, useEffect, useState } from 'react';

import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Loader,
} from '@ftrprf/tailwind-components';

import useTeacherCreateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateCalendarItem';
import useTeacherUpdateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherUpdateCalendarItem';
import useTeacherPrograms from 'hooks/api/plannerService/teacher/queries/useTeacherPrograms';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

import { UserContext } from 'providers/UserProvider';
import { errorKeys } from 'utils/constants/errorKeys';
import { useTeamsAuthenticationContext } from 'providers/TeamsLoginProvider';
import { setHours, setMinutes } from 'date-fns';
import { useForm } from 'react-hook-form';
import { isDateInThePast } from 'utils/timeHelpers';
import FormCalendarItem from './FormCalendarItem';
import { ProgramEmptyState } from '../Learn/ChapterItemOverview/partials/ProgramEmptyState';
import { setDefaultValues } from './utils/setDefaultValues';
import { setEventValues } from './utils/setEventValues';
import { submitCalendarItem } from './utils/submitCalendarItem';
import { isUpdate } from './utils/isUpdate';

export default function CalendarItemDialog({
  calendarModalOption,
  classGroups,
  getCalendarItems,
  itemInfo,
  onClose = () => {},
  updateItemOption,
}) {
  const { setShowTeamsAuthenticationDialog } = useTeamsAuthenticationContext();
  const { isTeamsUser, settings } = useContext(UserContext);
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm();
  const formOption = {
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
  };
  const {
    error: errorCreateCalendarItem,
    isLoading: saveLoading,
    mutate: createCalendarItem,
  } = useTeacherCreateCalendarItem();

  const { chapter, classInfo, eventTimeInfo, selectedEvent } = itemInfo;

  const {
    error: errorUpdateCalendarItem,
    isLoading: updateLoading,
    mutate: updateCalendarItemHook,
  } = useTeacherUpdateCalendarItem();

  const error = errorCreateCalendarItem || errorUpdateCalendarItem;
  const { isOpenModalCalendarItem, setIsOpenModalCalendarItem } =
    calendarModalOption;
  const { data: teacherPrograms, isLoading } = useTeacherPrograms();
  const { isUpdateMode, setIsUpdateMode } = updateItemOption;
  const sendToTeams = settings?.teams?.sendToTeams ?? false;
  const assignmentAddToCalendarAction =
    settings?.teams?.assignmentAddToCalendarAction;
  const [itemIsInThePast, setItemIsInThePast] = useState(
    eventTimeInfo?.itemIsInThePast,
  );
  const startHour = watch('startTimeHour')?.value;
  const startMinutes = watch('startTimeMinute')?.value;
  const date = watch('dateEvent');

  useEffect(() => {
    const hasNoToken = error?.message.includes(
      // errorKeys.TEAMS_ACCESS_TOKEN_NOT_FOUND,
      errorKeys.TEAMS_ACCESS_TOKEN_NOT_FOUND_TEMP,
    );

    if (hasNoToken) {
      setShowTeamsAuthenticationDialog(true);
      setTimeout(onClose, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const dateForItem = setMinutes(
      setHours(new Date(date), startHour),
      startMinutes,
    );
    setItemIsInThePast(isDateInThePast(dateForItem));
  }, [date, startHour, startMinutes]);

  useEffect(() => {
    setDefaultValues(
      setValue,
      itemInfo,
      isTeamsUser,
      sendToTeams,
      assignmentAddToCalendarAction,
    );
    setEventValues(setValue, itemInfo, {
      assignmentAddToCalendarAction,
      isTeams: isTeamsUser,
      sendToTeams,
    });
    // only do this if selectedEvent changes (aka, when the user first opens this dialog)
    // or when it's quickplan (aka, when chapter OR classInfo changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter, classInfo, eventTimeInfo, selectedEvent]);

  const handleCloseModalCalendarItem = () => {
    setIsOpenModalCalendarItem(false);
    setIsUpdateMode(false);
    setDefaultValues(
      setValue,
      itemInfo,
      isTeamsUser,
      sendToTeams,
      assignmentAddToCalendarAction,
    );
    onClose();
  };

  useEffect(() => {
    if (
      (!isUpdateMode && !errorCreateCalendarItem && !saveLoading) ||
      (isUpdateMode && !errorUpdateCalendarItem && !updateLoading)
    ) {
      getCalendarItems();
      handleCloseModalCalendarItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveLoading, updateLoading]);

  const t = useFormatMessage();

  const addCalendarItem = async (data) => {
    await createCalendarItem(data);
  };

  const updateCalendarItem = async (data) => {
    await updateCalendarItemHook(data);
  };

  const handleSubmitCalendarItem = (data) => {
    submitCalendarItem(
      data,
      isUpdateMode,
      updateCalendarItem,
      addCalendarItem,
      itemInfo,
      teacherPrograms,
    );
  };

  return (
    <Dialog
      className={`w-3/4 ${
        isLoading || saveLoading || updateLoading
          ? 'flex justify-center items-center'
          : ''
      }`}
      data-test={dataTestIds.modal.calendarItem.self}
      isOpen={isOpenModalCalendarItem}
      onDismiss={handleCloseModalCalendarItem}
    >
      {isLoading || saveLoading || updateLoading ? (
        <div className="p-4 w-10 h-10 my-10 w-full flex justify-center items-center">
          <Loader className="h-10" />
        </div>
      ) : (
        <>
          <DialogHeader>{t('calendar.modal.titleAddTittle')}</DialogHeader>
          <DialogContent>
            {Boolean(teacherPrograms?.length) ? (
              <FormCalendarItem
                classGroups={classGroups}
                formOption={formOption}
                itemInfo={itemInfo}
                teacherPrograms={teacherPrograms}
                teamsInfo={{
                  isTeams: isTeamsUser,
                  isUpdate: isUpdate(selectedEvent?.teamsCalendarItem),
                  itemIsInThePast,
                }}
                updateItemOption={updateItemOption}
              />
            ) : (
              <ProgramEmptyState />
            )}
          </DialogContent>
          <DialogActions>
            <ActionStyledAsButton
              onClick={() => handleCloseModalCalendarItem()}
              test={dataTestIds.modal.calendarItem.button.cancel}
              variant="text"
            >
              {t('global.cancel')}
            </ActionStyledAsButton>

            {Boolean(teacherPrograms?.length) && (
              <ActionStyledAsButton
                form="CalendarItem"
                onClick={handleSubmit(handleSubmitCalendarItem)}
                test={dataTestIds.modal.calendarItem.button.submit}
              >
                {isUpdateMode ? t('global.update') : t('global.save')}
              </ActionStyledAsButton>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
