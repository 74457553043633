/* eslint-disable no-param-reassign */
import { isBefore, subMinutes } from 'date-fns';
import { cloneDeep } from 'lodash-es';

import { OWN_CONTENT } from 'utils/constants/contentTypes';
import viewModes from 'utils/constants/viewModes';
import { getFormattedDates } from 'utils/timeHelpers';

export const statusInfo = {
  NOT_PLANNED: {
    text: 'global.not.planned',
    classes: 'bg-zinc-100 text-zinc-800',
    buttons: [
      {
        text: 'calendar.quickplan.plan',
      },
      {
        text: 'calendar.quickplan.open',
      },
    ],
  },
  PLANNED: {
    text: 'global.planned',
    classes: 'bg-yellow-100 text-yellow-800',
    buttons: [
      {
        text: 'calendar.quickplan.remove',
      },
      {
        text: 'calendar.quickplan.open',
      },
    ],
  },
  OPEN: {
    text: 'global.open',
    classes: 'bg-green-100 text-green-800',
    buttons: [
      {
        text: 'global.close',
      },
    ],
  },
};

function removeFirstButton(group) {
  group.shift();
}

export function isOpenCalendarItem(calendarItem, now) {
  const startDate = new Date(calendarItem.startTime);
  const startsBeforeNow = isBefore(startDate, now);
  const endDate = new Date(calendarItem.endTime);
  const endsBeforeNow = isBefore(endDate, now);
  const isClosed = calendarItem.closedAfterEndTime && endsBeforeNow;

  return !isClosed && startsBeforeNow;
}

function setButton(group, index, action, disabled = false) {
  const button = group.statusInfo.buttons?.[index];
  if (button) {
    group.statusInfo.buttons[index].disabled = disabled;
    group.statusInfo.buttons[index].action = action;
  }
}

export async function createNewCalendarItem(
  group,
  createCalendarItem,
  refetchLessons,
  itemInfo,
  teamsInfo,
) {
  const { end, start } = getFormattedDates(subMinutes(new Date(), 5));
  const lessonName = itemInfo?.chapter?.chapterItem?.name;
  const { assignmentAddToCalendarAction, isTeams, sendToTeams } = teamsInfo;

  const input = {
    chapterItemId: group.chapterId !== OWN_CONTENT ? group.chapterId : null,
    classGroupIds: [group.id],
    closedAfterEndTime: false,
    endTime: end,
    hidden: true,
    startTime: start,
    studioId: group.studioId,
    viewMode: viewModes.CLASSICAL,
  };

  if (isTeams && sendToTeams) {
    input.assignmentDescription = '';
    input.assignmentTitle = lessonName;
    input.addToCalendarAction = assignmentAddToCalendarAction;
    input.resourceDisplayName = `Open ${lessonName} in het FTRPRF Platform`;
  }

  await createCalendarItem(input);
  setTimeout(() => refetchLessons(), 500);
}

export async function closeCalendarItemsForGroup(
  group,
  updateCalendarItem,
  now = new Date(),
) {
  group.calendarItems
    .filter((item) => isOpenCalendarItem(item, now))
    .forEach((item) => {
      const endDate = new Date(item.endTime);
      const endsBeforeNow = isBefore(endDate, now);

      // ensure the lesson is closed.
      const input = {
        startTime: item.startTime,
        endTime: endsBeforeNow
          ? item.endTime
          : getFormattedDates(subMinutes(now, 5)).start,
        chapterItemId: item.chapterItemId || null,
        closedAfterEndTime: true,
        classGroupIds: item.classGroups.map((classGroup) => classGroup.id),
        viewMode: item.viewMode,
        studioId: item.studioId,
        hidden: item.hidden,
      };

      updateCalendarItem({ id: item.id, input });
    });
}

function setStatusNotPlanned(group, manipulationInfo, hasPlanButton) {
  const {
    createCalendarItem,
    isRetrievingOrUpdatingInfo,
    planCalendarItem,
    refetchLessons,
  } = manipulationInfo;
  const planButtonAction = async (group) => {
    await planCalendarItem({
      value: group.id,
      label: group.name,
    });

    setTimeout(() => refetchLessons(), 500);
  };
  const openButtonAction = async (group, itemInfo, teamsInfo) => {
    void createNewCalendarItem(
      group,
      createCalendarItem,
      refetchLessons,
      itemInfo,
      teamsInfo,
    );
  };

  group.statusInfo = cloneDeep(statusInfo.NOT_PLANNED);
  setButton(group, 0, planButtonAction, isRetrievingOrUpdatingInfo);
  setButton(group, 1, openButtonAction, isRetrievingOrUpdatingInfo);

  if (!hasPlanButton) removeFirstButton(group.statusInfo.buttons);
}

function setStatusOpen(group, manipulationInfo) {
  const { isRetrievingOrUpdatingInfo, refetchLessons, updateCalendarItem } =
    manipulationInfo;
  const closeButtonAction = async (group) => {
    await closeCalendarItemsForGroup(group, updateCalendarItem);
    setTimeout(() => refetchLessons(), 500);
  };

  group.statusInfo = statusInfo.OPEN;
  setButton(group, 0, closeButtonAction, isRetrievingOrUpdatingInfo);
}

function setStatusPlanned(group, manipulationInfo) {
  const {
    createCalendarItem,
    deleteCalendarItem,
    isRetrievingOrUpdatingInfo,
    refetchLessons,
  } = manipulationInfo;
  const removeButtonAction = (group) => {
    const { calendarItems } = group;
    calendarItems.forEach((item) => deleteCalendarItem({ id: item.id }));
    setTimeout(() => refetchLessons(), 500);
  };
  const openButtonAction = async (group, itemInfo, teamsInfo) => {
    void createNewCalendarItem(
      group,
      createCalendarItem,
      refetchLessons,
      itemInfo,
      teamsInfo,
    );
  };

  group.statusInfo = statusInfo.PLANNED;
  setButton(group, 0, removeButtonAction, isRetrievingOrUpdatingInfo);
  setButton(group, 1, openButtonAction, isRetrievingOrUpdatingInfo);
}

export function setStatusInfo(
  group,
  calendarItemsForClassGroup,
  manipulationInfo,
  hasPlanButton,
) {
  const isPlanned = calendarItemsForClassGroup.length > 0;

  if (!isPlanned) {
    setStatusNotPlanned(group, manipulationInfo, hasPlanButton);
    return;
  }

  if (group.hasOpenItem) {
    setStatusOpen(group, manipulationInfo);
    return;
  }

  setStatusPlanned(group, manipulationInfo);
}
