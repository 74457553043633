/* drag & drop requires prop spreading */
/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { generatePath, useNavigate } from 'react-router-dom';
import { ArrowsPointingOutIcon, PlusIcon } from '@heroicons/react/24/outline';
import {
  BookOpenIcon,
  PencilIcon,
  UsersIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

import {
  Actions,
  ActionStyledAsButton,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
} from '@ftrprf/tailwind-components';

import ListSkeleton from 'components/SkeletonLoader/ListSkeleton';
import { SKELETON_OPTIONS } from 'components/SkeletonLoader/SkeletonConstructor';

import { UserContext } from 'providers/UserProvider';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import { laptop } from 'utils/constants/mediaWidth';
import URLS from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';

import ProgressBar from 'components/ProgressBar';
import {
  droppedOutsideTheDroppable,
  hasADifferentPosition,
  lockTableStyles,
  setItemWeight,
  unlockTableStyles,
} from './helpers';
import ExtraExplanation from '../../../components/ExtraExplanation';

export default function ManagePrograms({
  currentPage,
  filter,
  isLoadingPrograms,
  itemsPerPage,
  openAddModal = () => {},
  openDeleteProgramModal,
  openEditModal,
  programs,
  setFilter,
  setItemsPerPage,
  setPage,
  setProgramData,
  setSort,
  sort,
  totalItems,
  totalPages,
  updateProgram,
}) {
  const t = useFormatMessage();
  const [draggedId, setDraggedId] = useState();
  const navigate = useNavigate();
  const { isAdmin, isSchoolAdmin } = useContext(UserContext);
  useTitle(t('manage.title'), t('global.PROGRAM.plural'));
  const routeToProgramDetails = (programId) =>
    generatePath(URLS.MANAGE_PROGRAM_DETAILS, {
      filter,
      programId,
    });

  const routeToProgramUsage = (programId) =>
    generatePath(URLS.MANAGE_PROGRAM_USAGE, {
      filter,
      programId,
    });

  const goToProgramDetails = ({ id: programId }) => {
    navigate(routeToProgramDetails(programId));
  };

  const isDragDisabled =
    (sort?.weight === undefined && sort !== undefined) || !updateProgram;

  const actionsForProgram = (program) => {
    const actions = [
      {
        icon: <BookOpenIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.content'),
        testName: dataTestIds.page.manage.table.button.content,
        href: routeToProgramDetails(program.id),
      },
    ];

    if (isAdmin) {
      if (openEditModal) {
        actions.push({
          icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
          testName: dataTestIds.page.manage.table.button.edit,
          label: t('global.edit'),
          onClick: () => {
            setProgramData(program);
            openEditModal();
          },
        });
      }

      if (openDeleteProgramModal) {
        actions.push({
          icon: <XCircleIcon className="h-4 w-4 inline-block mr-2" />,
          testName: dataTestIds.page.manage.table.button.delete,
          label: t('global.deactivate'),
          onClick: () => {
            setProgramData(program);
            openDeleteProgramModal();
          },
        });
      }
    }

    if (isSchoolAdmin) {
      actions.push({
        icon: <UsersIcon className="h-4 w-4 inline-block mr-2" />,
        testName: dataTestIds.page.manage.table.button.usage,
        label: t('global.usage'),
        href: routeToProgramUsage(program.id),
      });
    }

    return actions;
  };

  const onBeforeDragStart = (start) => {
    setDraggedId(start.draggableId);
    document
      .querySelector(
        `[data-rbd-drag-handle-draggable-id="${start.draggableId}"]`,
      )
      .classList.add('shadow-lg');
    lockTableStyles();
  };

  const onDragStart = ({ draggableId }) => {
    const program = programs.filter(
      (program) => program.id === Number(draggableId),
    )[0];
    setProgramData(program);
  };

  const onDragEnd = ({ destination, source }) => {
    document
      .querySelector(`[data-rbd-drag-handle-draggable-id="${draggedId}"]`)
      .classList.remove('shadow-lg');
    setDraggedId(null);
    unlockTableStyles();

    if (droppedOutsideTheDroppable(destination)) {
      return;
    }

    if (hasADifferentPosition(destination, source)) {
      const program = programs[source.index];

      setItemWeight(programs, destination, source);
      updateProgram({
        name: program.name,
        type: program.type,
        weight: program.weight,
        color: program.color,
      });

      programs.sort(sortByWeightThenByName);
    }
  };

  return (
    <>
      <div className="w-full flex justify-between">
        <div className="justify-between w-1/2">
          <SearchBar
            className="mb-4"
            id={dataTestIds.page.manage.input.name}
            onChange={setFilter}
            placeholder={t('hack-room.what_are_you_looking_for')}
            test={dataTestIds.page.manage.input.name}
          />
        </div>
        {isAdmin && (
          <ActionStyledAsButton
            className="mb-4"
            iconBefore={PlusIcon}
            onClick={() => openAddModal(true)}
            test={dataTestIds.page.manage.button.add.program}
          >
            {t('program-overview.new-program')}
          </ActionStyledAsButton>
        )}
      </div>
      <ListSkeleton
        loading={isLoadingPrograms}
        size={SKELETON_OPTIONS.LINE_H_LG}
      >
        <DragDropContext
          onBeforeDragStart={onBeforeDragStart}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <Droppable droppableId="full-list">
            {(provided1) => (
              <div
                className="w-full"
                {...provided1.droppableProps}
                ref={provided1.innerRef}
              >
                <ResponsiveTable
                  childrenLength={programs?.length}
                  emptyText={
                    Boolean(filter.length)
                      ? t('program-overview.empty.search')
                      : t('program-overview.empty')
                  }
                  headers={
                    isSchoolAdmin
                      ? [
                          {
                            key: 'icon',
                            orderable: false,
                            className: 'sr-only',
                          },
                          {
                            key: 'name',
                            label: t('program-overview.column.name'),
                          },
                          {
                            extraLabel: t('reports.SEAT_UPDATE_FREQUENCY'),
                            key: 'seats',
                            label: t('reports.SEAT_USAGE'),
                            orderable: false,
                          },
                          {
                            key: 'actions',
                            orderable: false,
                            className: 'sr-only',
                          },
                        ]
                      : [
                          {
                            key: 'icon',
                            orderable: false,
                            className: 'sr-only',
                          },
                          {
                            key: 'name',
                            label: t('program-overview.column.name'),
                          },
                          {
                            key: 'type',
                            label: t('program-overview.column.type'),
                          },
                          { key: 'weight', label: t('global.weight') },
                          {
                            key: 'actions',
                            orderable: false,
                            className: 'sr-only',
                          },
                        ]
                  }
                  mobileBreakpoint={laptop}
                  mobileHeaderWidth={160}
                  onRowClick={goToProgramDetails}
                  onSortChange={(newSort) => setSort(newSort)}
                  sort={sort}
                >
                  {programs?.map((program, index) => (
                    <Draggable
                      key={program.id}
                      draggableId={`${program.id}`}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided2) => (
                        <ResponsiveTableRow
                          className="bg-white draggable-item"
                          data={isDragDisabled && program}
                          draggableProps={provided2.draggableProps}
                          dragHandleProps={provided2.dragHandleProps}
                          innerRef={provided2.innerRef}
                        >
                          <ResponsiveTableCol className="px-2 py-2">
                            <div className="flex flex-row gap-x-2 items-center">
                              {!isDragDisabled && (
                                <ArrowsPointingOutIcon className="hidden lg:block h-5 w-5 mx-2 rotate-45 text-gray-400" />
                              )}
                              <div
                                className="rounded-full h-3 w-3"
                                style={{
                                  backgroundColor: program.color,
                                }}
                              />
                              <div className="w-7">
                                {program.blobUri && (
                                  <img
                                    alt=""
                                    className="h-7 w-7 rounded"
                                    src={program.blobUri}
                                  />
                                )}
                              </div>
                            </div>
                          </ResponsiveTableCol>
                          <ResponsiveTableCol>
                            {program.name}
                          </ResponsiveTableCol>

                          {isSchoolAdmin && (
                            <ResponsiveTableCol>
                              {Boolean(program.maxSeats) ? (
                                <ProgressBar
                                  hasVisibleLabel
                                  label={`${program.totalUsed}/${program.maxSeats}`}
                                  percent={
                                    (program.totalUsed / program.maxSeats) * 100
                                  }
                                />
                              ) : (
                                '-'
                              )}
                            </ResponsiveTableCol>
                          )}

                          {!isSchoolAdmin && (
                            <ResponsiveTableCol>
                              {program.type}
                            </ResponsiveTableCol>
                          )}

                          {!isSchoolAdmin && (
                            <ResponsiveTableCol className="text-center">
                              {program.weight}
                            </ResponsiveTableCol>
                          )}

                          <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right justify-end">
                            <Actions items={actionsForProgram(program)} />
                          </ResponsiveTableCol>
                        </ResponsiveTableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided1.placeholder}
                </ResponsiveTable>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isSchoolAdmin && Boolean(programs?.length) && (
          <ExtraExplanation translationKey="reports.SEAT_UPDATE_FREQUENCY_EXPLANATION" />
        )}

        {Boolean(programs && programs.length) && (
          <Pagination
            className="mt-12"
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={(page) => setPage(page)}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
            totalPages={totalPages}
          />
        )}
      </ListSkeleton>
    </>
  );
}
