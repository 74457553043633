import usePrograms from 'hooks/api/plannerService/shared/usePrograms';

import { generatePath, useParams } from 'react-router-dom';
import { useContext } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { UserContext } from 'providers/UserProvider';
import URLS, { chapterItemTabs } from 'utils/constants/urls';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';
import Learn from './Learn';
import StudentChapterItemOverview from './ChapterItemOverview/StudentChapterItemOverview';
import TeacherChapterItemOverview from './ChapterItemOverview/TeacherChapterItemOverview';

export default function LearnContainer() {
  const t = useFormatMessage();
  const { chapterId, programId } = useParams();
  const { data: programs, isLoading } = usePrograms();
  const sortedPrograms = programs?.sort(sortByWeightThenByName);
  const { isStudent, isTeacher } = useContext(UserContext);

  const currentProgram =
    programId &&
    sortedPrograms?.find((program) => String(program.id) === programId);

  const currentChapter =
    chapterId &&
    currentProgram?.chapters
      ?.sort(sortByWeightThenByName)
      .find((chapter) => String(chapter.id) === chapterId);

  let pageTitle = currentChapter
    ? currentChapter.name
    : t('curricula.overview');
  if (!currentChapter && currentProgram) pageTitle = currentProgram.name;
  useTitle(pageTitle);

  const homeLink = URLS.LEARN;
  const breadcrumbsLinks = [{ href: homeLink, name: t('curricula.overview') }];

  if (currentProgram) {
    breadcrumbsLinks.push({
      name: currentProgram.name,
      href: generatePath(URLS.LEARN_PROGRAM, {
        programId: currentProgram.id,
      }),
    });
  }

  if (currentChapter) {
    const linkVariables = {
      programId: currentProgram.id,
      chapterId: currentChapter.id,
    };
    const url = isTeacher
      ? URLS.LEARN_TAB_PROGRAM_CHAPTER
      : URLS.LEARN_PROGRAM_CHAPTER;

    if (isTeacher) linkVariables.tab = chapterItemTabs.DEFAULT;

    breadcrumbsLinks.push({
      name: currentChapter.name,
      href: generatePath(url, linkVariables),
      current: true,
    });
  }

  /* ChapterItemOverview */
  if (isStudent && currentChapter) {
    return (
      <StudentChapterItemOverview
        breadcrumbsLinks={breadcrumbsLinks}
        currentChapter={currentChapter}
        isLoading={isLoading}
        pageTitle={pageTitle}
        sortedPrograms={sortedPrograms}
      />
    );
  }

  if (currentChapter) {
    return (
      <TeacherChapterItemOverview
        breadcrumbsLinks={breadcrumbsLinks}
        currentChapter={currentChapter}
        isLoading={isLoading}
        pageTitle={pageTitle}
        sortedPrograms={sortedPrograms}
      />
    );
  }

  return (
    <Learn
      breadcrumbsLinks={breadcrumbsLinks}
      currentChapter={currentChapter}
      currentProgram={currentProgram}
      isLoading={isLoading}
      pageTitle={pageTitle}
      sortedPrograms={sortedPrograms}
    />
  );
}
