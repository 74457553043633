import { SlideViewerTextSlide } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { dataTestIds } from 'utils/dataTestIds';

export function TeacherManual({ currentSlide, showSolution }) {
  const t = useFormatMessage();

  return (
    <div
      className="overflow-y-auto max-h-[calc(100%-1.25rem)] overflow-x-hidden bg-white p-5 rounded"
      data-test={dataTestIds.multiScreen.currentSlideTeacherManual}
    >
      {showSolution && (
        <div className="overflow-y-auto flex-grow">
          <h4 className="font-bold mb-4">
            {t('styled-slide-viewer.example_answer')}
          </h4>
          <div>{currentSlide.question?.solution}</div>
        </div>
      )}
      <div className={showSolution && 'mt-4'}>
        {showSolution && (
          <h4 className="font-bold mb-4">{t('global.manual')}</h4>
        )}
        {currentSlide.info ? (
          <SlideViewerTextSlide value={currentSlide.info} />
        ) : (
          <p>{t('multi-screen-mode.no-manual')}</p>
        )}
      </div>
    </div>
  );
}
