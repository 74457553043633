import { errorKeys } from 'utils/constants/errorKeys';

export function getIsSingleErrorMessage(messages) {
  const isSingleGeneralError =
    (messages.length === 1 && !messages[0].errorCategoryName) ||
    messages[0].errorCategoryName?.includes('GENERAL') ||
    messages[0].errorCategoryName?.includes(
      errorKeys.MICROSOFT_GRAPH_CALL_FAILED,
    );

  const generalErrors = messages.filter(
    (message) =>
      !messages[0].errorCategoryName ||
      message.errorCategoryName?.includes('GENERAL'),
  );
  const hasOnlyGeneralErrors = messages.length === generalErrors.length;

  return !!(isSingleGeneralError || hasOnlyGeneralErrors);
}
