import { useEffect, useMemo, useState } from 'react';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import GeneralLayout from 'app/layouts/GeneralLayout';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import { Tab, TabList } from 'components/Tab';
import { useCalendarItemById } from 'hooks/api/plannerService/shared/useCalendarItemById';
import { useTeacherStudentsByCalendarItemId } from 'hooks/api/plannerService/teacher/queries/useTeacherStudentsByCalendarItemId';
import useCurrentTab from 'hooks/useCurrentTab';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { nonNumericProgramIds } from 'utils/constants/nonNumericProgramIds';
import { sortOperation } from 'utils/constants/sort';
import URLS, { studentResultTab, userProfileTabs } from 'utils/constants/urls';
import VIEWMODES from 'utils/constants/viewModes';
import { generateStudentResultPageTabPath } from 'utils/generateUrlPaths';
// the below is suppressed 'cause it is in the project dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string';
import { FetchLessonReport } from './partials/FetchLessonReport';
import { StudentPager } from './partials/StudentPager';
import { getFirstMatchingClassgroup, getStudentIndex } from './helpers';
import { dataTestIds } from '../../utils/dataTestIds';

export function ResultsForTeacher({ searchParams }) {
  const t = useFormatMessage();
  const {
    calendarItemId,
    programId,
    sessionId: lessonSessionId,
    userId,
  } = useParams();
  const currentTab = useCurrentTab();
  const defaultViewmodeFromSearchParams =
    queryString.parse(searchParams)?.viewmode;

  const [studentId, setStudentId] = useState(userId);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [activeViewMode, setActiveViewMode] = useState(
    defaultViewmodeFromSearchParams,
  );

  const availableViewModes = [VIEWMODES.CLASSICAL, VIEWMODES.SELFSTUDY];
  const [max, setMax] = useState(0);
  const [denominator, setDenominator] = useState(0);
  const { data: calendarItem } = useCalendarItemById(calendarItemId);
  const [currentStudentIndex, setCurrentStudentIndex] = useState();
  const [currentStudent, setCurrentStudent] = useState({});

  const chapterItem = calendarItem?.chapterItem;
  const pageTitle = chapterItem
    ? chapterItem?.name
    : calendarItem?.lessonContentTitle;

  const filter = [];

  const { data: studentsData } = useTeacherStudentsByCalendarItemId({
    calendarItemId,
    filter,
    page: 0,
    size: 999,
    sort: { fullname: sortOperation.ASC },
  });

  const students = studentsData?.content;

  useEffect(() => {
    if (students) setCurrentStudentIndex(getStudentIndex(students, userId));
  }, [students, userId]);

  useEffect(() => {
    if (currentStudentIndex) setCurrentStudent(students[currentStudentIndex]);
  }, [currentStudentIndex, students]);

  useTitle(
    t('content.results'),
    `${currentStudent?.firstName} ${currentStudent?.lastName}`,
  );

  let firstBreadcrumbLink = useMemo(
    () => ({
      href: generatePath(URLS.CALENDAR_ITEM_RESULTS, {
        calendarItemId,
        programId,
      }),
      search: queryString.stringify(searchParams),
      name: `${pageTitle}`,
    }),
    [calendarItemId, pageTitle, programId, searchParams],
  );

  if (programId === nonNumericProgramIds.CLASS) {
    const classGroup =
      calendarItem?.classGroups?.length === 1 || !currentStudent
        ? calendarItem.classGroups[0]
        : getFirstMatchingClassgroup(
            calendarItem?.classGroups,
            currentStudent.classGroups,
          );

    if (classGroup) {
      firstBreadcrumbLink = {
        href: generatePath(URLS.CLASSGROUP_STUDENT_PROFILE_TAB, {
          classGroupId:
            calendarItem?.classGroups?.length === 1 || !currentStudent
              ? calendarItem.classGroups[0].id
              : classGroup.id,
          studentId,
          tab: userProfileTabs.RESULTS,
        }),
        name: t('results-overview.breadcrumbs.results.for.class', {
          name: classGroup.name,
        }),
      };
    }
  }

  if (programId === nonNumericProgramIds.AGENDA) {
    firstBreadcrumbLink = {
      href: generatePath(URLS.HOME),
      name: t('header.navigation.home'),
    };
  }

  const breadcrumbsLinks = useMemo(
    () =>
      [
        firstBreadcrumbLink,
        currentStudent
          ? {
              name: `${
                currentStudent?.firstName ||
                students?.[currentStudentIndex]?.firstName
              } ${
                currentStudent?.lastName ||
                students?.[currentStudentIndex]?.lastName
              }`,
            }
          : {
              name: t('global.STUDENT.singular'),
            },
      ].filter(Boolean),
    [currentStudent, currentStudentIndex, firstBreadcrumbLink, students, t],
  );

  useEffect(() => {
    setStudentId(userId);
  }, [userId]);

  useEffect(() => {
    if (!activeViewMode) {
      setActiveViewMode(calendarItem?.viewMode);
    }
  }, [activeViewMode, calendarItem]);

  return (
    <GeneralLayout>
      <FetchLessonReport
        activeViewMode={activeViewMode}
        lessonSessionId={lessonSessionId}
        setData={setData}
        setDenominator={setDenominator}
        setError={setError}
        setMax={setMax}
        studentId={studentId}
      />
      <PageHeaderWrapper
        borderBottom={false}
        breadcrumbs={breadcrumbsLinks}
        button={
          currentTab === studentResultTab.RESPONSES && max ? (
            <strong className="text-white" data-test="studentScore">
              <span className="sr-only">
                {t('results-overview.column.score')}:
              </span>
              {` ${denominator} / ${max}`}
            </strong>
          ) : null
        }
        buttonOnTitleRow
        marginBottom={false}
        title={pageTitle}
      >
        {Boolean(
          students?.length && calendarItemId && currentStudentIndex >= 0,
        ) && (
          <StudentPager
            calendarItemId={calendarItemId}
            currentStudentIndex={currentStudentIndex}
            sessionId={lessonSessionId}
            setStudentId={setStudentId}
            students={students}
            tab={currentTab}
          />
        )}

        <TabList>
          <Tab
            label={t('global.RESPONSE.plural')}
            test={dataTestIds.page.results.tabs.exercise}
            to={generateStudentResultPageTabPath(
              calendarItemId,
              programId,
              lessonSessionId,
              studentResultTab.RESPONSES,
              userId,
            )}
          />

          <Tab
            label={t('global.SCRATCH_EXERCISE.plural')}
            test={dataTestIds.page.results.tabs.scratch}
            to={generateStudentResultPageTabPath(
              calendarItemId,
              programId,
              lessonSessionId,
              studentResultTab.SCRATCH_EXERCISES,
              userId,
            )}
          />
        </TabList>
      </PageHeaderWrapper>

      <div className="w-full max-w-5/6 my-4 mx-auto">
        <Outlet
          context={[
            {
              data,
              setData,
              error,
              setError,
              activeViewMode,
              setActiveViewMode,
              denominator,
              setDenominator,
              max,
              setMax,
              studentId,
              setStudentId,
              lessonSessionId,
              calendarItem,
              availableViewModes,
            },
          ]}
        />
      </div>
    </GeneralLayout>
  );
}
