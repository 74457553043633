import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import viewModes from 'utils/constants/viewModes';

export function queryParamsDefinition() {
  return {
    fullname: withDefault(StringParam, ''),
    classgroups: withDefault(ArrayParam, []),
    viewmode: withDefault(StringParam, viewModes.CLASSICAL),
    page: withDefault(NumberParam, 1),
  };
}
