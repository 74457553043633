import {
  Actions,
  Avatar,
  Badge,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';
import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';
import { laptop } from 'utils/constants/mediaWidth';
import { Idp } from 'components/Idp';
import useFormatMessage from 'hooks/useFormatMessage';
import { useState } from 'react';

export function UserTable({
  actionsForOrganisation,
  displayRoleFn,
  isLoading,
  paginationVariables,
  users,
}) {
  const t = useFormatMessage();
  const [sort, setSort] = useState(1);
  const {
    itemsPerPage,
    page,
    setItemsPerPage,
    setPage,
    totalItems,
    totalPages,
  } = paginationVariables;

  return (
    <TableSkeleton loading={isLoading}>
      <ResponsiveTable
        headers={[
          {
            key: 'fullName',
            label: t('global.name'),
            orderable: false,
          },
          {
            key: 'username',
            label: t('global.username'),
            orderable: false,
          },
          {
            key: 'email',
            label: t('global.email'),
            orderable: false,
          },
          {
            key: 'role',
            label: t('global.role'),
            orderable: false,
          },
          {
            key: 'idp',
            label: t('global.idp'),
            orderable: false,
          },
          {
            key: 'actions',
            label: t('global.actions'),
            orderable: false,
            className: 'max-w-fit sr-only',
          },
        ]}
        mobileBreakpoint={laptop}
        mobileHeaderWidth={160}
        onSortChange={(newSort) => setSort(newSort)}
        sort={sort}
      >
        {users?.map((user) => (
          <ResponsiveTableRow key={user.id} data={user}>
            <ResponsiveTableCol>
              <Avatar avatar={user.avatar} />
              {user.firstName} {user.lastName}
            </ResponsiveTableCol>
            <ResponsiveTableCol>{user.userName}</ResponsiveTableCol>
            <ResponsiveTableCol>{user.email}</ResponsiveTableCol>
            <ResponsiveTableCol>
              {user.roles.map((role) => (
                <Badge
                  key={role}
                  danger={role.includes('ADMIN')}
                  success={role.includes('STUDENT')}
                  warning={role.includes('TEACHER')}
                >
                  {displayRoleFn(role)}
                </Badge>
              ))}
            </ResponsiveTableCol>
            <ResponsiveTableCol>
              <Idp idp={user.idp} />
            </ResponsiveTableCol>
            <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
              <Actions items={actionsForOrganisation(user)} />
            </ResponsiveTableCol>
          </ResponsiveTableRow>
        ))}
      </ResponsiveTable>

      {Boolean(users?.length) && (
        <Pagination
          className="mt-12"
          currentPage={page}
          itemsPerPage={itemsPerPage}
          setCurrentPage={(page) => setPage(page)}
          setItemsPerPage={setItemsPerPage}
          totalItems={totalItems}
          totalPages={totalPages}
        />
      )}
    </TableSkeleton>
  );
}
