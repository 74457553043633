import { useEffect, useState } from 'react';

import {
  Content,
  Filter,
  Pagination,
  ResponsiveTable,
  SearchBar,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';

import { dataTestIds } from 'utils/dataTestIds';
import { ViewModePicker } from 'components/Form/ViewModePicker';
import { CalendarItemResultRow } from './partials/CalendarItemResultRow';

export function CalendarItemResultsOverview({
  activeViewMode,
  calendarItem,
  classGroups,
  currentPage,
  filterClassGroup,
  filterStudents,
  isLoading,
  itemsPerPage,
  searchParams,
  setActiveViewMode,
  setFilter,
  setFilterClassGroup,
  setItemsPerPage,
  setPage,
  students,
  totalItems,
  totalPages,
}) {
  const t = useFormatMessage();
  const [amountOfQuestions, setAmountOfQuestions] = useState(0);
  const [amountOfOpenQuestions, setAmountOfOpenQuestions] = useState(0);

  useEffect(() => {
    if (students) {
      const reportOverallScore = students
        .find((student) => student.reportOverallScores)
        ?.reportOverallScores.find(
          (report) => report.viewMode === activeViewMode,
        );
      setAmountOfQuestions(reportOverallScore?.amountOfQuestions || 0);
      setAmountOfOpenQuestions(reportOverallScore?.amountOfOpenQuestions || 0);
    }
  }, [
    students,
    setAmountOfQuestions,
    setAmountOfOpenQuestions,
    activeViewMode,
  ]);

  return (
    <Content hasPaddingBottom>
      <div className="w-full mx-auto">
        <div className="flex items-center">
          <div className="my-4 mr-5">
            <SearchBar
              data-test="nameFilter"
              defaultValue={filterStudents}
              onChange={(value) => setFilter(value, 'fullname')}
              placeholder={t('global.search.by.name')}
            />
          </div>
          <div className="my-4 mr-5">
            {classGroups && (
              <Filter
                activeFilters={
                  filterClassGroup?.map((id) => Number(id)) || classGroups
                }
                allowNoneSelected
                displayOptionFn={(classGroupId) =>
                  classGroups.find(
                    (classGroup) => classGroup.id === classGroupId,
                  ).name
                }
                name={t('global.class')}
                options={classGroups?.map((classGroup) => classGroup.id)}
                setFilter={setFilterClassGroup}
                test="classFilter"
              />
            )}
          </div>
          <div className="mt-4 mb-2 mr-5">
            <ViewModePicker
              activeViewMode={activeViewMode}
              setActiveViewMode={setActiveViewMode}
            />
          </div>
        </div>

        <TableSkeleton loading={isLoading}>
          <ResponsiveTable
            emptyText={t('results-overview.calendar_item.no-students-found')}
            headers={[
              {
                key: 'name',
                label: t('results-overview.column.name'),
                orderable: true,
              },
              {
                key: 'class',
                label: t('global.class'),
                orderable: false,
              },
              // {
              //   key: 'date',
              //   label: t('results-overview.calendar_item.last_opened_at'),
              //   orderable: true,
              // },
              // {
              //   key: 'times_opened',
              //   label: t('results-overview.calendar_item.times_opened'),
              //   orderable: true,
              // },
              {
                className: 'text-center',
                key: 'score',
                label: t('results-overview.column.score'),
                orderable: true,
              },
              {
                className: 'text-center',
                key: 'progress',
                label: t('results-overview.column.progress'),
                orderable: false,
              },
              {
                className: 'text-center',
                key: 'grading',
                label: t('results-overview.column.grading'),
                orderable: false,
              },
              {
                className: 'max-w-fit sr-only',
                key: 'actions',
                label: t('global.actions'),
                orderable: false,
              },
            ]}
            mobileBreakpoint={768}
            mobileHeaderWidth={160}
            test={dataTestIds.page.learn.results.table.self}
          >
            {students?.map((student) => (
              <CalendarItemResultRow
                key={student.id}
                amountOfOpenQuestions={amountOfOpenQuestions}
                amountOfQuestions={amountOfQuestions}
                calendarItem={calendarItem}
                report={student.reportOverallScores?.find(
                  (report) => report.viewMode === activeViewMode,
                )}
                searchParams={searchParams}
                student={student}
              />
            ))}
          </ResponsiveTable>

          {Boolean(students && students.length) && (
            <Pagination
              className="mt-12"
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              itemsPerPageOptions={[50, 100, 500]}
              setCurrentPage={(page) => setPage(page)}
              setItemsPerPage={setItemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          )}
        </TableSkeleton>
      </div>
    </Content>
  );
}
