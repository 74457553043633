import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addHours } from 'date-fns';

import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  Logo,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';

import { Steps } from 'components/Steps/Steps';
import { SmartschoolButton } from 'components/Buttons/SmartschoolButton';

import useFormatMessage from 'hooks/useFormatMessage';
import useTeacherCreateCalendarItem from 'hooks/api/plannerService/teacher/mutations/useTeacherCreateCalendarItem';
import useTeacherClassGroups from 'hooks/api/plannerService/teacher/queries/useTeacherClassGroups';
import useTeacherPrograms from 'hooks/api/plannerService/teacher/queries/useTeacherPrograms';

import { viewModes } from 'utils/constants/viewModes';
import { OWN_CONTENT } from 'utils/constants/contentTypes';
import { backendAcceptedDateFormat } from 'utils/timeHelpers';

import { SelectTarget } from './partials/SelectTarget';
import { SelectLesson } from './partials/SelectLesson';
import { SelectDisplay } from './partials/SelectDisplay';
import { getStudioIdForLesson } from '../Calendar/getStudioIdForLesson';
import { postResponse } from './partials/postResponse';

export default function SmartschoolPopup() {
  const t = useFormatMessage();
  const user = useContext(UserContext);
  const { organization } = user;
  const [classes, setClasses] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [open, setOpen] = useState(true);
  const [target, setTarget] = useState({
    description: t('smartschool.popup.target.all.description'),
    id: 0,
    name: t('smartschool.popup.target.all.name'),
  });
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      chapter: '',
      chapterItem: '',
      classGroups: classes,
      program: '',
      target,
      viewMode: viewModes.CLASSICAL,
    },
  });
  const { chapterItem } = watch();
  const { data: teacherPrograms, isLoading: isLoadingTeacherPrograms } =
    useTeacherPrograms();
  const steps = useMemo(
    () => [
      { disabled: false, id: '01', name: t('smartschool.popup.step1') },
      { disabled: false, id: '02', name: t('smartschool.popup.step2') },
      {
        disabled: !chapterItem?.value,
        id: '03',
        name: t('smartschool.popup.step3'),
      },
    ],
    [chapterItem, t],
  );

  const { data: classGroupsData, isLoading: isLoadingClasses } =
    useTeacherClassGroups({
      page: 0,
      size: 500,
    });

  const {
    isLoading: createCalendarItemLoading,
    mutateAsync: createCalendarItem,
  } = useTeacherCreateCalendarItem();

  const addCalendarItem = async () => {
    const { chapter, chapterItem, program, viewMode } = watch();
    const currentTime = new Date();
    const oneHourAway = addHours(currentTime, 1);
    const input = {
      chapterItemId: chapterItem?.value,
      classGroupIds: classes.map(
        (classGroup) => classGroup?.value || classGroup?.id, // first if it's not the all classes option, second if it is
      ),
      closedAfterEndTime: false,
      endTime: backendAcceptedDateFormat(oneHourAway),
      hidden: true,
      startTime: backendAcceptedDateFormat(currentTime),
      studioId:
        program?.value === OWN_CONTENT
          ? chapterItem?.studioId
          : getStudioIdForLesson(
              teacherPrograms,
              program?.value,
              chapter?.value,
              chapterItem?.value,
            ),
      viewMode: viewMode?.value ?? viewModes.CLASSICAL,
    };

    return createCalendarItem(input);
  };

  const onDismiss = () => {
    setOpen(false);
  };

  const onTargetClick = (newTarget) => {
    if (target?.id === newTarget?.id) return;

    if (newTarget?.id === 0) {
      setClasses(classGroupsData?.content);
    } else {
      const { classGroups } = watch();
      setClasses(classGroups);
    }

    setTarget(newTarget);
  };

  useEffect(() => {
    const hasRetrievedClassGroups =
      classGroupsData && Boolean(classGroupsData.content);
    if (hasRetrievedClassGroups && target.id === 0 && !classes.length)
      setClasses(classGroupsData.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classGroupsData]);

  const showNextStepButton =
    (isLoadingClasses || Boolean(classGroupsData?.content?.length)) &&
    (isLoadingTeacherPrograms || Boolean(teacherPrograms?.length));

  return (
    <Dialog
      className="overflow-visible md:min-h-[24rem] md:min-w-[43rem]"
      isOpen={open}
      modal
      onDismiss={onDismiss}
    >
      <DialogContent>
        <Logo className="mb-4" organization={organization} />
        <Steps
          className="mb-4"
          currentStepIndex={currentStepIndex}
          id="use_in_smartschool"
          setCurrentStepIndex={setCurrentStepIndex}
          steps={steps}
        />
        <SelectTarget
          classes={classGroupsData}
          formOptions={{ control, errors, register }}
          hidden={currentStepIndex !== 0}
          onTargetClick={onTargetClick}
          setClasses={setClasses}
          target={target}
        />
        <SelectLesson
          formOptions={{ control, errors, setValue, watch }}
          hidden={currentStepIndex !== 1}
          organization={organization}
          teacherPrograms={teacherPrograms}
        />
        <SelectDisplay
          formOptions={{
            control,
            errors,
            watch,
          }}
          hidden={currentStepIndex !== 2}
        />
      </DialogContent>
      <DialogActions>
        {currentStepIndex !== 2 ? (
          <>
            <ActionStyledAsButton
              onClick={
                currentStepIndex === 0
                  ? onDismiss
                  : () => setCurrentStepIndex(currentStepIndex - 1)
              }
              variant="text"
            >
              {currentStepIndex === 0
                ? t('global.cancel')
                : t('global.previous')}
            </ActionStyledAsButton>
            {showNextStepButton && (
              <ActionStyledAsButton
                disabled={steps[currentStepIndex + 1].disabled}
                onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
              >
                {t('smartschool.popup.next', { step: currentStepIndex + 2 })}
              </ActionStyledAsButton>
            )}
          </>
        ) : (
          <SmartschoolButton
            isLoading={createCalendarItemLoading}
            onClick={async () => {
              await addCalendarItem().then((data) => {
                postResponse(data, teacherPrograms, watch);
              });
            }}
            text={t('smartschool.popup.use')}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
