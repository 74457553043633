import { CheckIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import useFormatMessage from '../../hooks/useFormatMessage';

export function MiniCopyToClipoardButton({ text }) {
  const t = useFormatMessage();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  return (
    <CopyToClipboard onCopy={() => setCopied(true)} text={text}>
      <button type="button">
        <ClipboardDocumentIcon className="w-4 ml-2 inline-block" />
        <span className="sr-only">{t('global.copyToClipboard')}</span>

        <CheckIcon
          className={`w-4 text-green-600 inline-block transition-all ${
            copied ? 'opacity-100' : 'opacity-0'
          }`}
        />
      </button>
    </CopyToClipboard>
  );
}
