import { generatePath, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

import {
  Actions,
  ActionStyledAsButton,
  Pagination,
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
  SearchBar,
  Toggle,
} from '@ftrprf/tailwind-components';

import TableSkeleton from 'components/SkeletonLoader/TableSkeleton';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import URLS, { organizationTabs } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';

export default function ManageOrganizations({
  currentPage,
  handleShowCalendarClick,
  handleShowInactiveClick,
  isLoading,
  isNotActiveChecked,
  isShowCalendarChecked,
  itemsPerPage,
  openActivateModal,
  openAddModal = () => {},
  openDeactivateModal,
  openEditModal,
  organizations,
  setFilter,
  setItemsPerPage,
  setOrganizationData,
  setPage,
  setSort,
  sort,
  totalItems,
  totalPages,
}) {
  const t = useFormatMessage();
  const navigate = useNavigate();
  useTitle(t('manage.title'), t('global.ORGANIZATION.plural'));

  const routeToDetail = (organization) =>
    navigate(
      generatePath(URLS.MANAGE_ORGANIZATION_TAB, {
        organizationId: organization.id,
        tab: organizationTabs.DEFAULT,
      }),
    );

  const actionsForOrganisation = (organization) => {
    const possibleActions = [
      {
        icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
        testName: dataTestIds.page.manage.table.button.view,
        label: t('global.view'),
        onClick: () => routeToDetail(organization),
      },
    ];

    possibleActions.push({
      icon: <PencilIcon className="h-4 w-4 inline-block mr-2" />,
      label: t('global.edit'),
      testName: dataTestIds.page.manage.table.button.edit,
      onClick: () => {
        setOrganizationData(organization);
        openEditModal();
      },
    });

    if (organization.active) {
      possibleActions.push({
        icon: <XCircleIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.deactivate'),
        testName: dataTestIds.page.manage.table.button.archive,
        onClick: () => {
          setOrganizationData(organization);
          openDeactivateModal();
        },
      });
    } else {
      possibleActions.push({
        icon: <CheckCircleIcon className="h-4 w-4 inline-block mr-2" />,
        label: t('global.activate'),
        testName: dataTestIds.page.manage.table.button.unarchive,
        onClick: () => {
          setOrganizationData(organization);
          openActivateModal();
        },
      });
    }

    return possibleActions;
  };

  return (
    <>
      <div className="w-full flex md:justify-between mb-4 gap-4 flex-col md:flex-row">
        <div className="flex-grow">
          <SearchBar
            id={dataTestIds.page.manage.input.name}
            onChange={setFilter}
            placeholder={t('hack-room.what_are_you_looking_for')}
            test={dataTestIds.page.manage.input.name}
          />
        </div>
        <div className="md:px-8 flex items-center">
          <Toggle
            label={t('organization-overview.not-active')}
            onChange={handleShowInactiveClick}
            test={dataTestIds.page.manage.toggle.showArchived}
            value={isNotActiveChecked}
            visibleLabel
          />
        </div>
        <div className="md:px-8 flex items-center">
          <Toggle
            label={t('organization-overview.no-calendar')}
            onChange={handleShowCalendarClick}
            test={dataTestIds.page.manage.toggle.showCalendar}
            value={isShowCalendarChecked}
            visibleLabel
          />
        </div>
        <ActionStyledAsButton
          iconBefore={PlusIcon}
          onClick={openAddModal}
          test={dataTestIds.page.manage.button.add.organisation}
        >
          {t('organization-overview.button.add-organization')}
        </ActionStyledAsButton>
      </div>
      <TableSkeleton loading={isLoading}>
        {organizations && (
          <>
            <ResponsiveTable
              emptyText={t('organization-overview.empty')}
              headers={[
                { key: 'name', label: t('organization-overview.column.name') },
                {
                  key: 'prefix',
                  label: t('organization-overview.column.usernamePrefix'),
                },
                {
                  key: 'language',
                  label: t('organization-overview.column.language'),
                  orderable: false,
                },
                {
                  key: 'issuer',
                  label: t('organization-overview.column.issuer'),
                  orderable: false,
                },
                {
                  key: 'calendar',
                  label: t('calendar.show'),
                  orderable: false,
                },
                {
                  key: 'actions',
                  label: t('global.actions'),
                  orderable: false,
                  className: 'max-w-fit sr-only',
                },
              ]}
              mobileBreakpoint={768}
              mobileHeaderWidth={160}
              onRowClick={routeToDetail}
              onSortChange={(newSort) => setSort(newSort)}
              sort={sort}
              test={dataTestIds.page.manage.table.self}
            >
              {organizations.map((organization) => (
                <ResponsiveTableRow key={organization.id} data={organization}>
                  <ResponsiveTableCol>{organization.name}</ResponsiveTableCol>
                  <ResponsiveTableCol>{organization.prefix}</ResponsiveTableCol>
                  <ResponsiveTableCol>
                    {organization.language}
                  </ResponsiveTableCol>
                  <ResponsiveTableCol>{organization.issuer}</ResponsiveTableCol>
                  <ResponsiveTableCol>
                    {organization.showCalendar && (
                      <CheckIcon className="h-4 w-4 inline-block ml-11" />
                    )}
                  </ResponsiveTableCol>
                  <ResponsiveTableCol className="px-6 py-2 whitespace-nowrap text-right">
                    <Actions items={actionsForOrganisation(organization)} />
                  </ResponsiveTableCol>
                </ResponsiveTableRow>
              ))}
            </ResponsiveTable>

            {Boolean(organizations && organizations.length) && (
              <Pagination
                className="mt-12"
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setCurrentPage={(page) => setPage(page)}
                setItemsPerPage={setItemsPerPage}
                totalItems={totalItems}
                totalPages={totalPages}
              />
            )}
          </>
        )}
      </TableSkeleton>
    </>
  );
}
