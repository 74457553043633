import './theme/tailwind.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-toastify/dist/ReactToastify.min.css';

import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
// import { PrismicProvider } from '@prismicio/react';
// import * as Sentry from '@sentry/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import LanguageProvider from 'providers/LanguageProvider';
import LocationHistoryProvider from 'providers/LocationHistoryProvider';
import NotificationProvider from 'providers/NotificationProvider';
import { ReportsProvider } from 'providers/ReportsProvider';
import TailwindProvider from 'providers/TailwindProvider';
import UserProvider from 'providers/UserProvider';

import initSentry from 'utils/initSentry';
import queryClient from 'utils/queryClient';

import { PeriodsProvider } from 'providers/PeriodsProvider';
import { toastClassnameIdentifierId } from 'utils/dataTestIds';
import TeamsAuthenticationProvider from 'providers/TeamsLoginProvider';
import App from './app/App';

import msalInstance from './msalInstance';
// import { client } from './prismic';

initSentry();

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_HIDE_OVERLAY
) {
  // eslint-disable-next-line import/no-extraneous-dependencies
  import('hide-cra-error-overlay').then(({ initHideOverlay }) =>
    initHideOverlay(),
  );
}

// MSAL has the option to work with multiple accounts, when a user has expired token
// we send them back to AD but if they log in with a different account (or same Smartschool User)
// it will still register as a differen account and you will have multiple ones in cache
const accounts = msalInstance.getAllAccounts();

if (accounts?.length > 0) {
  let newestAccount = accounts[0];

  // Log multiple users to sentry, just for some logging.
  // if (accounts.length > 1) {
  //   Sentry.captureMessage('Multiple accounts found', {
  //     extra: { accounts },
  //   });
  // }

  // if multiple accounts are found in cache we will just select the one that has the latest
  // authorization time, so the last one that was used to log in. In the future we could make some
  // kind of fancy user switcher, but let's be honest, that will never happen - Jannick 10.10.2022
  accounts.forEach((account) => {
    if (
      newestAccount.idTokenClaims.auth_time < account.idTokenClaims.auth_time
    ) {
      newestAccount = account;
    }
  });

  msalInstance.setActiveAccount(newestAccount);
}

// This seems to be needed to find an active account right after returning from the login page.
// Once you're logged in and refresh the page this no longer kicks in, dont really get it yet.
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK === 'true'
) {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  void worker.start();
}

if (process.env.NODE_ENV === 'production') {
  console.log(`
  =============================================================================================
  __________________________ ________ ________ __________
  ___  ____/___  __/___  __ \\___  __ \\___  __ \\___  ____/
  __  /_    __  /   __  /_/ /__  /_/ /__  /_/ /__  /_
  _  __/    _  /    _  _, _/ _  ____/ _  _, _/ _  __/
  /_/       /_/     /_/ |_|  /_/      /_/ |_|  /_/    v${process.env.REACT_APP_VERSION}
  =============================================================================================
  `);

  console.log(
    'Wanted: Passionate developers. React / Java Spring Boot. Send a mail to katelijne@ftrprf.be with your CV and motivation!',
  );

  window.console = {
    log: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  };
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MsalProvider instance={msalInstance}>
      <UserProvider>
        <LanguageProvider>
          {/* <PrismicProvider client={client}> */}
          <TailwindProvider>
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <Router>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <LocationHistoryProvider>
                  <ToastContainer
                    newestOnTop
                    pauseOnHover
                    toastClassName={toastClassnameIdentifierId}
                  />
                  <NotificationProvider>
                    <ReportsProvider>
                      <PeriodsProvider>
                        <TeamsAuthenticationProvider>
                          <App />
                        </TeamsAuthenticationProvider>
                      </PeriodsProvider>
                    </ReportsProvider>
                  </NotificationProvider>
                </LocationHistoryProvider>
              </QueryParamProvider>
            </Router>
          </TailwindProvider>
          {/* </PrismicProvider> */}
        </LanguageProvider>
      </UserProvider>
    </MsalProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
