export const english = {
  'dropdown.no_results': "No options found",
  'global.account.avatar': 'Avatar',
  'global.account.avatar.save': 'Save avatar',
  'global.account.info': 'Account information',
  'global.actions': 'Actions',
  'global.activate': 'Activate',
  'global.archive': 'Archive',
  'global.archived': 'Archived',
  'global.at': 'at',
  'global.buypackages': "Buy more packages",
  'global.cancel': 'Cancel',
  'global.calendar': 'Calendar',
  'global.class': 'Class group',
  'global.close': 'Close',
  "global.color": "color",
  'global.configure_users': 'Configure users',
  'global.confirm': "Create",
  'global.content': 'Content',
  'global.copyToClipboard': 'Copy text',
  'global.deactivate': 'Deactivate',
  'global.default.via.profile.part1': 'You can set the default value of this in ',
  'global.default.via.profile.part2': 'your profile.',
  'global.delete': 'Delete',
  'global.download': 'Download',
  'global.drag_and_drop_or_browse.excel': "Drag and drop an excel file, or browse files.",
  'global.drag_and_drop_or_browse.image': "Drag and drop an image, or browse files.",
  'global.edit': 'Edit',
  'global.edit_user': 'Edit user',
  'global.edit_password': 'Edit password',
  'global.email': 'E-mail',
  'global.empty': "Looks like nothing's here.",
  'global.empty-lesson': 'Empty lesson',
  'global.empty.adjust_filter': 'Adjust your filter to see more results.',
  'global.error': "Oops! Something went wrong.",
  'global.error.message': "We don't know exactly what went wrong. Please try again in a few minutes. If things continue to go wrong, please contact support@ftrprf.be.",
  'global.example': "Example",
  'global.export': 'Export',
  'global.file.delete': "Delete file",
  'global.given_by': 'Given by',
  'global.go_back': 'Go back',
  'global.general.info': 'General info',
  'global.hack_room': 'Hack Room',
  'global.hide': 'Hide',
  'global.hourAbbreviation': 'h',
  'global.idp': 'Login',
  'global.image.add': "Add image",
  'global.image.delete': "Delete image",
  'global.image.update': "Update image",
  'global.impersonate': 'Impersonate',
  'global.import': 'Import',
  'global.indicator': 'indicator',
  'global.items.per.page': 'Items per page',
  'global.link.classgroups': "Add class group",
  'global.link.tags': "Add tag",
  'global.link.programs': 'Add program',
  'global.loading': 'Loading...',
  'global.login.with.microsoft': 'Login with Microsoft',
  'global.manual': 'Manual',
  'global.name': "name",
  'global.next': "Next",
  'global.none': 'None',
  'global.no_results': 'No results found',
  'global.not.planned': 'Not scheduled',
  'global.on': 'on',
  'global.only_word_characters': 'A domain prefix may only contain letters, numbers, and a hyphen.',
  'global.oops': 'Oops',
  'global.open': 'Open',
  'global.planned': 'Planned',
  'global.presentation': 'Presentation',
  'global.previous': 'Previous',
  'global.program': 'Program',
  'global.redirecting': 'Redirecting...',
  'global.reload': 'Reload',
  'global.remove': 'Remove',
  'global.remove.classgroup': 'Remove class group',
  'global.role': 'Role',
  'global.save.changes': 'Save changes',
  'global.search.by.name': 'Search by name',
  'global.selected': 'Selected',
  'global.smartschool': 'Smartschool',
  'global.start': 'Start',
  'global.status': 'Status',
  'global.teams': 'Teams',
  'global.usage': 'Usage',
  'global.userId': 'User Id',
  'global.show': 'Show',
  'global.showErrors': 'Show errors',
  'global.showMore': 'Show more',
  'global.to': 'To',
  'global.today': 'Today',
  'global.tomorrow': 'Tomorrow',
  'global.unimpersonate': 'End impersonation',
  'global.unknown': 'Unknown',
  'global.viewMode': 'Viewmode',
  'global.weight': "Weight / Importance",
  'global.yesterday': 'Yesterday',

  // calendar
  'calendar.show': "Show calendar",

  // validation
  'global.required-field': 'Required field',
  'global.required-number': 'This field must be a number.',
  'global.max-length-255': 'This field can\'t contain more than 255 characters.',
  'global.min-length-8': 'This field must contain at least 8 characters.',
  'global.min-length-12': 'This field must contain at least 12 characters.',
  'global.missing.values': 'Missing values',
  'global.password': 'Password',
  'global.password-pattern': 'The password must be at least 8 characters and include at least one lowercase letter, one uppercase letter and one number character.',
  'global.present': 'Present',
  'global.progress': 'Progress',
  'global.reset-password': 'Reset password',
  'global.reset-password.confirm': 'Reset',
  'global.save': 'Save',
  'global.save-changes': 'Save changes',
  'global.select.all': 'Select all',
  'global.select.none': 'Select none',
  'global.snooze': 'Snooze',
  'global.taskInfoStatus.all': 'All',
  'global.taskInfoStatus.completed': 'Completed',
  'global.taskInfoStatus.failed': 'Failed',
  'global.taskInfoStatus.in_progress': 'In progress',
  'global.total-amount': 'total amount',
  'global.try-again': 'Try again',
  'global.unlink.program': 'Delete program',
  'global.unlink.tags': 'Unlink tag',
  'global.unlink.classgroup': 'Delete class',
  'global.update': 'Update',
  'global.username': 'Username',
  'global.view': 'View',
  'global.work_in_progress': 'Work in progress',

  // accessibility-related stuff
  'global.keyboard.skiplink': 'Skip to {destination}',
  'global.sr.about': 'about',

  // language
  'global.LANGUAGE.singular': 'Language',
  'global.LANGUAGE.select': 'Select language',

  // success
  'global.activate.success': '{what} activated successfully',
  'global.add.success': '{what} added successfully',
  'global.archive.success': '{what} archived successfully',
  'global.create.success': '{what} created successfully',
  'global.deactivate.success': '{what} deactivated successfully',
  'global.delete.success': '{what} deleted successfully',
  'global.disable.success': '{what} disabled successfully',
  'global.enable.success': '{what} enabled successfully',
  'global.move.success': '{what} moved successfully',
  'global.post.success': 'the {what} sent successfully',
  'global.remove.success': '{what} removed successfully',
  'global.update.success': '{what} updated successfully',


  // error
  'global.activate.error': 'Something went wrong during the activation of {what}',
  'global.add.error': 'Something went wrong while adding {what}',
  'global.archive.error': 'Something went wrong during the archivation of {what}',
  'global.create.error': 'Something went wrong during the creation of {what}',
  'global.deactivate.error': 'Something went wrong during the deactivation of {what}',
  'global.delete.error': 'Something went wrong during the removal of {what}',
  'global.disable.error': 'Something went wrong during the disabling of {what}',
  'global.enable.error': 'Something went wrong during the enabling of {what}',
  'global.get.error': 'Something went wrong during the retrieval of {what}',
  'global.move.error': 'Something went wrong while moving the {what}',
  'global.not_found.error': "{what} couldn't be found",
  'global.post.error': 'Something went wrong while sending the {what}',
  'global.remove.error': 'Something went wrong while removing {what}',
  'global.update.error': 'Something went wrong during the modification of {what}',

  // generic messages
  "global.add.what": "Add {what}",
  "global.create.what": "Create {what}",
  "global.delete.what": "Delete {what}",
  'global.delete.what.confirm': "Are you sure you want to delete this {what}?",
  'global.empty.search': "No {what} matching this search criteria were found.",
  'global.empty.link': 'Currently no {what} have been linked to {thing}.',
  "global.update.what": "Update {what}",
  "global.link.what": "Link {what}",
  "global.unlink.what": "Unlink {what}",
  "global.manage.what": "Manage {what}",

  // warning
  'global.no.users.warning': 'No users available for personalised tests.',

  // global articles
  'global.THE': 'the',
  'global.THEH': 'the',
  'global.THE.plural': 'the',

  // global student
  'global.LESSON.plural': 'Lessons',
  'global.LESSON.singular': 'Lesson',
  'global.LESSON.view': 'View lesson',
  'global.lesson.new': 'Create new lesson',

  // global classgroup
  'global.CLASSGROUP.id': 'Class ID',
  'global.CLASSGROUP.name': 'Class name',
  'global.CLASSGROUP.plural': 'class groups',
  'global.CLASSGROUP.singular': 'class group',

  // global exam
  'global.EXAM.plural': 'Exams',
  'global.EXAM.singular': 'Exam',
  'global.EXAM.view': 'View exam',

  // global role
  'global.FTRPRF_SCHOOLSTUDENT.singular': 'Student',
  'global.FTRPRF_SCHOOLTEACHER.singular': 'Teacher',
  'global.FTRPRF_SCHOOLADMIN.singular': 'School admin',
  'global.FTRPRF_ADMIN.singular': 'Admin',
  'global.SCHOOLADMIN.singular': "School admin",
  'global.SCHOOLADMIN.plural': "School admins",

  // global enabled
  'global.enabled.true': 'Activated users',
  'global.enabled': 'Enabled',
  'global.disabled': 'Disabled',
  'global.enabled.false': 'Deactivated users',

  // global student
  'global.STUDENT.plural': 'Students',
  'global.STUDENT.singular': 'Student',
  'global.STUDENT.view': 'View student',

  // global class
  'global.CLASS.plural': 'Class groups',
  'global.CLASS.singular': 'Class group',
  'global.CLASS.view': 'View class group',

  // global result
  'global.RESULT.plural': 'Results',
  'global.RESULT.singular': 'Result',
  'global.RESULT.view': 'View result',

  // global teacher
  'global.TEACHER.plural': 'Teachers',
  'global.TEACHER.singular': 'Teacher',
  'global.TEACHER.view': 'View teacher',

  // global user
  'global.USER.plural': 'Users',
  'global.USER.singular': 'User',
  'global.USER.view': 'View user',

  // global school
  'global.SCHOOL.plural': 'Schools',
  'global.SCHOOL.singular': 'School',
  'global.SCHOOL.view': 'View school',

  // global program
  'global.PROGRAM.plural': 'Programs',
  'global.PROGRAM.singular': 'Program',
  'global.PROGRAM.view': 'View program',
  'global.PROGRAM.add': 'Add program',

  // global exercise
  'global.EXERCISE.plural': 'Exercises',
  'global.EXERCISE.singular': 'Exercise',
  'global.EXERCISE.view': 'View exercise',

  // global exercise
  'global.SCRATCH_EXERCISE.plural': 'Scratch-exercises',
  'global.SCRATCH_EXERCISE.singular': 'Scratch-exercise',
  'global.SCRATCH_EXERCISE.view': 'View Scratch-exercise',

  // global test
  'global.TEST.plural': 'Tests',
  'global.TEST.singular': 'Test',
  'global.TEST.view': 'View test',

  // global antwoord
  'global.RESPONSE.plural': 'Responses',
  'global.RESPONSE.singular': 'Response',
  'global.RESPONSE.view': 'Check responses',

  // global organization
  'global.ORGANIZATION.plural': 'Organisations',
  'global.ORGANIZATION.singular': 'Organisation',
  'global.ORGANIZATION.view': 'View organisation',

  // global organization
  'global.FILE.plural': 'Files',
  'global.FILE.singular': 'File',
  'global.FILE.view': 'View file',

  // global calendaritems
  'global.CALENDARITEM.plural': 'Calendar items',
  'global.CALENDARITEM.singular': 'Calendar item',
  'global.CALENDARITEM.view': 'View calendar item',

  // global chapter
  'global.CHAPTER.plural': 'Chapters',
  'global.CHAPTER.singular': 'Chapter',
  'global.CHAPTER.view': 'View chapter',

  // global chapteritem
  'global.CHAPTERITEM.plural': 'Chapter items',
  'global.CHAPTERITEM.singular': 'Chapter item',
  'global.CHAPTERITEM.view': 'View chapter item',

  // global slide
  'global.SLIDE.plural': 'Slides',
  'global.SLIDE.singular': 'Slide',
  'global.SLIDE.view': 'View slide',

  // global answer
  'global.ANSWER.plural': 'Answers',
  'global.ANSWER.singular': 'Answer',
  'global.ANSWER.view': 'View Answer',

  // global slide
  'global.CONTENT.singular': 'Content',
  'global.CONTENT.view': 'View content',

  // global notification
  "global.NOTIFICATION.plural": "Notifications",
  "global.NOTIFICATION.singular": "Notification",
  "global.NOTIFICATION.view": "View notification",

  // global feedback
  "global.FEEDBACK": "Feedback",
  "global.FEEDBACK.view": "View feedback",

  // global score
  "global.SCORE.plural": "Scores",
  "global.SCORE.singular": "Score",

  // global learn
  'global.LEARN': 'Learn',

  // global personality
  'global.personality.test.plural': "personality tests",

  // global validation
  "global.validation.unique.what": "{what} should be unique.",

  // column headers
  'global.table-header.errorMessages': 'Error messages',
  'global.table-header.imported.classes': 'Imported classes',
  'global.table-header.imported.users': 'Imported users',
  'global.table-header.started_on': 'Started on',
  'global.table-header.status': 'State',

  // assessment status
  "assessment.status.created": "Requested",
  "assessment.status.finished": "Completed",
  "assessment.status.started": "Started",
  "assessment.status.not_send": "Not send",

  // ChapterItem Report Table
  'chapterItemReport.dialog.title.questions': 'Number of questions answered per calendar item',
  'chapterItemReport.dialog.title.score': 'Scores per calendar item',
  'chapterItemReport.filter.score': 'Show scores',
  'chapterItemReport.filter.questions': 'Show number of questions answered',
  'chapterItemReport.tooltip.multiple_questions': 'There are several calendar items, click for more information',
  'chapterItemReport.no-results-found': 'No results were found for this lesson',
  'chapterItemReport.tooltip.multiple_no_grade_classical': 'Classroom on {startTime}',
  'chapterItemReport.tooltip.multiple_no_grade_selfstudy': 'Tutorial on {startTime}',
  'chapterItemReport.tooltip.to_grade': '{amountToGrade} to improve open questions.',

  // import dialog
  "import.dialog.submission.error": 'There were problems with the import.',
  'import.dialog.description': 'Only excel files (with extension: .xls and .xlsx) will be accepted',
  'import.dialog.file.invalid': 'Invalid file',
  'import.dialog.file.unsupported': 'Unsupported file type',
  'import.dialog.file.valid': 'Valid file',
  'import.dialog.title': 'Import students and classes',
  'import.excel': 'Import Excel',
  'import.excel.description': 'Here you can easily import students and/or teachers (who are assigned to a certain class) from an Excel file, by downloading the template and filling it in. After filling in the template, upload the file by clicking on the button below.',
  'import.excel.documentation': 'View template documentation',
  'import.excel.download': 'Download template',
  'import.excel.max': 'Note: there is a maximum of 2000 users / import.',
  'import.task': 'the information',

  // viewModes
  'view-modes.projection': 'Projection',
  'view-modes.selfstudy': 'Home',
  'view-modes.classical': 'Classroom',

  // Header
  'header.hello': 'Hello, ',
  'header.help': 'How-to',
  'header.help_videos': 'How-to videos',
  'header.help_videos.tag': 'How-to videos for the category {category}',
  'header.logout': 'Log out',
  'header.manage_admin': 'Manage admin',
  'header.manage_school': 'Manage school',
  'header.navigation.classes': 'Class groups',
  'header.navigation.exams': 'Exams',
  'header.navigation.home': 'Home',
  'header.navigation.manage': 'Manage',
  'header.navigation.profile': 'Profile',
  'header.navigation.students': 'Pupils',
  'header.navigation.studio': 'Studio',
  'header.navigation.tools': 'Tools',
  'header.no.active.period.schooladmin': 'There is currently no active period. Please contact support@ftrprf.be.',
  'header.no.active.period.studentAndTeacher': "There is currently no active period. Please contact your school admin.",
  'header.old_manage_school': 'Old manage panel',
  'header.profile': 'Profile',
  'header.uiSettings': 'User preferences',

  // Calendar
  'calendar.item.view_results': 'See results',
  'calendar.modal.closeAfterEndTime': 'Close after end time',
  'calendar.modal.dateEvent': 'Date of the lesson',
  'calendar.modal.dateNowValidate': 'The lesson can\'t take place in the past.',
  'calendar.modal.editLessonContent': 'Do you want to change the lesson?',
  'calendar.modal.endHour': 'Starting hour',
  'calendar.modal.endMinute': 'Starting minute',
  'calendar.modal.endTime': 'End time',
  'calendar.modal.extended': 'Show workday',
  'calendar.modal.lesson': 'Select version',
  'calendar.modal.normal': 'Show full day',
  'calendar.modal.selectChapter': 'Select chapter',
  'calendar.modal.selectChapterItem': 'Select chapter item',
  'calendar.modal.selectClass': "Select class group",
  'calendar.modal.selectProgram': 'Select program',
  'calendar.modal.selectViewMode': 'Select view mode',
  'calendar.modal.startHour': 'Starting hour',
  'calendar.modal.startMinute': 'Starting minute',
  'calendar.modal.startTime': 'Start time',
  'calendar.modal.startTime<endTime': 'The end time can\'t be earlier than the start time.',
  'calendar.modal.teams.assignmentDescription': 'Which description do you want for this assignment? (optional)',
  'calendar.modal.teams.assignmentTitle': 'What should the title be for this assignment?',
  'calendar.modal.teams.dateInThePast': 'The start date you chose for this calendar item is in the past. Unfortunately, Teams does not allow creating items in the past. So you will see your item in FTRPRF, but not in Teams.',
  'calendar.modal.teams.resourceDisplayName': 'What is the resourceDisplayName',
  'calendar.modal.title': 'Schedule a lesson',
  'calendar.modal.titleAddTittle': 'Schedule a lesson',
  'calendar.modal.week': 'Show full week',
  'calendar.modal.workweek': 'Show workweek',
  'calendar.no_classes': 'No classes',
  'calendar.no_teachers': 'No teachers',
  'calendar.title': 'Calendar',
  'calendar.welcome': 'Welcome',

  // Quickplan Modal for Calendar
  'calendar.quickplan.close.all': 'Close for all classes',
  'calendar.quickplan.open': 'Open',
  'calendar.quickplan.open.all': 'Open for all classes',
  'calendar.quickplan.plan': 'Plan in calendar',
  'calendar.quickplan.remove': 'Remove from calendar',


  // Side calendar classes
  'side-calendar-classes.no-classes': 'No class groups selected',

  // Content
  'content.settings': 'Settings',
  'content.end_goals': 'End-goals',
  'content.what_are_you_looking_for': 'What are you looking for?',
  'content.close.dialog': "Close",
  'content.results': 'Results',
  'content.info': 'Info',
  'content.infoLessons': 'Lessons info',
  'content.tags': 'Tags',
  'content.archive': 'Archive',
  'content.copyAndEdit': 'Copy and open in Studio',
  'content.edit': 'Edit',
  'content.unarchive': 'Unarchive',

  // Content overview
  'content-details.status': 'Status',
  'content-details.download': 'Documents',

  // Results overview
  'results-overview.average': 'Average',
  'results-overview.breadcrumbs.results.for.class': 'Results for {name}',
  'results-overview.calendar_item.breadcrumb.classes': 'Results class groups ',
  'results-overview.calendar_item.breadcrumb.student': 'Results student ',
  'results-overview.calendar_item.last_opened_at': 'Last opened at',
  'results-overview.calendar_item.no-students-found': 'No students were found for this class group.',
  'results-overview.calendar_item.no_results_scratch_no_judge': 'no co-teacher',
  'results-overview.calendar_item.times_opened': 'Times opened',
  'results-overview.class': 'class group',
  'results-overview.column.graded_suffix': 'graded open questions',
  'results-overview.column.grading': 'Grading',
  'results-overview.column.name': 'Name',
  'results-overview.column.progress': 'Progress',
  'results-overview.column.progress_suffix': 'questions answered by the student',
  'results-overview.column.results': 'Results',
  'results-overview.column.score': 'Score',
  'results-overview.column.submitted_at': 'Submitted at',
  'results-overview.export_excel': 'Excel Spreadsheet',
  'results-overview.export_failed': 'Export failed',
  'results-overview.export_smartschool': 'Score Points book',
  'results-overview.export_successful': 'Export completed successfully',
  'results-overview.home': 'home',
  'results-overview.made_in_version': 'Made in version',
  'results-overview.no-results': 'No answers have been submitted.',
  'results-overview.no_complete_average': 'This is a temporary average because not all questions are graded.',
  'results-overview.not_all_questions_answered_warning': 'not answered all questions.',
  'results-overview.not_all_questions_have_scores_warning': 'unscored questions.',
  'results-overview.not_fully_graded': 'Not all answers are graded.',
  'results-overview.not_fully_submitted': 'The student has not submitted all answers yet.',
  'results-overview.publish_results': 'Publish results',
  'results-overview.publish_results_warnings': 'Warning',
  'results-overview.student_has': 'student has',
  'results-overview.students_have': 'students have',
  'results-overview.title.class': 'Class',
  'results-overview.title.results': 'Results',
  'results-overview.unanswered_question': 'unanswered question',
  'results-overview.unanswered_questions': 'unanswered questions',
  'results-overview.unpublish_results': 'Hide results',
  'results-overview.version': 'Version',
  'results-overview.view': 'view',
  'results_overview.calendar_item.loading': "Loading data for this student, this may take a second.",
  'results_overview.calendar_item.no_results': "This student didn't give any responses yet.",
  'results_overview.calendar_item.no_results_scratch': "This student didn't make any Scratch exercises yet.",
  'results_overview.calendar_item.no_results_scratch_student': "You didn't make any Scratch exercises yet.",
  'results_overview.calendar_item.no_results_student': "You didn't give any responses yet.",
  'results_overview.get.student_results': 'student information',
  'results_overview.questions.feedback': 'Feedback',
  'results_overview.results.for': "results for",
  'results_overview.results.for.student': "result for",
  'results_overview.score.between': 'The score must be between {min} and {max}.',
  'results_overview.student.result': "Student result",
  'results_overview.student.results': "Student results",

  'student-answers.title.results': 'Results',
  'student-answers.title.total': 'Total',
  'student-answers.question_label': 'question',
  'student-answers.no_questions': 'There are no questions in this view mode.',
  'student-answers.no_results_published': 'There are no published results yet.',
  'student-answers.given_answer': 'Given answer',
  'student-answers.example_solution': 'Example solution',
  'student-answers.click_to_show_question': 'Show question',
  'student-answers.click_to_hide_question': 'Hide question',
  'student-answers.no_content': 'Nothing to show',
  'student-answers.correct_answer': 'The correct answer is:',
  'student-answers.no_answer': 'No example answer provided',

  'question-result.no-result': 'There is no answer for this question.',

  // StudentSwitcher
  'student-switcher.next': 'next',
  'student-switcher.previous': 'previous',

  // Errors
  'errors.401.header': 'Unauthorized',
  'errors.401.message.conclusion': 'If you believe you are seeing this page by mistake, be sure to contact us at {email}.',
  'errors.401.message.cta': 'request a demo',
  'errors.401.message.description': "We do not recognize your account, this could be due to {amountOfReasons} reasons:",
  'errors.401.message.intro': 'Welcome to FTRPRF!',
  'errors.401.message.reason.no_contract_with_ftrprf': 'Your school has not signed an agreement with us. For more information, take a look at our website {website} or {cta} right now!',
  'errors.401.message.reason.not_synchronized_with_smartschool': 'The person responsible at your school has not yet synchronized with SmartSchool, please contact the person in charge.',
  'errors.403.header': 'Access forbidden',
  'errors.403.message': "You have no access to this page.  If you think you should have access to this page, please contact your school admin.",
  'errors.404.header': 'Page not found',
  'errors.404.message.main': "You are in uncharted waters: this page does not exist.",
  'errors.404.message.sub': "It may have been removed or moved to a different URL.",
  'errors.action.home': 'Back to the home page',
  'errors.back': 'Back',
  'errors.category.missing.fields.no.other.errors.clarification': 'You can find below which users are involved and which fields are missing. We show as much information as possible to facilitate solving the error.',
  'errors.category.missing.fields.no.other.errors.title': 'There were users with missing fields.',
  'errors.category.tooManyUsers': 'There may be a maximum of 2000 users in Excel. Split the excel so that there is a maximum of 2000 users / file.',
  'errors.category.userGenerationFailed': 'Failed to create {user}.',
  'errors.change_eula': `Couldn't update EULA`,
  'errors.change_language': `Couldn't update language`,
  'errors.delete_chapter_item': `There are one or more calendar items for it. You have to delete the calendar items in order to delete this lesson.`,
  'errors.message.no-page': 'No page was found with that URL.',
  'errors.message.smartschool': `Your user account was created today and will be active starting tomorrow. Then, you will be able to explore the FTRPRF learning platform. If you have been receiving this message for multiple days in a row, please contact your school admin.`,
  'errors.no-classgroups': 'You have not been associated with a class group. Contact your school admin.',
  'errors.set_publish_status': `Couldn't update publish status.`,
  'errors.set_results_published': `Couldn't set results as published.`,
  'errors.unknown': "We don't know what went wrong. If this continues, please contact our support.",
  'errors.update_score': `Couldn't update score.`,

  // Info Impersonator Among Us
  'impersonator.joke': "There is an impersonator!",
  'impersonator.alt': "among us",
  "impersonator.header": 'Please remember to act like a normal crewmate',

  // impersonate
  "impersonate.header": "We are boarding, please remain seated...",
  "impersonate.header.failed": "We tried to get you aboard",
  "impersonate.description": "Unfortunately, you can't start your adventure yet. Please try again in a moment!",

  // publishStatuses
  'publish-statuses.PUBLISHED': 'Published',
  'publish-statuses.OPEN_FOR_HOME': 'Open for self-study',
  'publish-statuses.OPEN_FOR_CLASSGROUP': 'Open for class group',
  'publish-statuses.CLOSED': 'Closed',
  'publish-statuses.explanation.OPEN_FOR_HOME': 'The students see the home version.',
  'publish-statuses.explanation.OPEN_FOR_CLASSGROUP': 'The students see the classroom version.',
  'publish-statuses.explanation.CLOSED': "The students can't see this lesson.",

  // curricula
  'curricula.overview': 'Learning materials',
  'curricula.overview.button': 'Overview',
  'curricula.overview.plan': 'Schedule',
  'curricula.overview.plan.quick': 'Schedule all',
  'curricula.programs.empty': 'There is currently no content associated with your account. Please contact the school administrator with the request to link content.',
  'curricula.LESSON.content-overview.TEACHER.empty': 'No lessons have been published yet.',
  'curricula.LESSON.content-overview.STUDENT.empty': 'No lessons have been opened yet.',
  'curricula.lessons.own': 'Own lessons',
  'curricula.lessons.school': 'School lessons',
  'curricula.lessons.own.none': 'No lessons of this type have been published' +
    ' to date.',
  'curricula.lessons.own.create': 'Get started by creating your own student(s)!',

  'curricula.EXAM.title': 'Exam overview',
  'curricula.EXAM.content-overview.TEACHER.empty': 'No exams have been published yet.',
  'curricula.EXAM.content-overview.STUDENT.empty': 'No exams have been opened yet.',

  // Class Group
  'class-group.name': 'Name',
  'class-group.details': 'Details',
  'class-group.linked': 'Linked to',
  'class-group.errors.something_went_wrong': "The class group details cannot be shown at the moment.",
  'class-group.same-name.error': 'There\'s already a class group with this name.',
  'class-group.add-user-to-class-group': 'Add user to class group',
  'class-group.add-user': 'Add users',

  // Profile
  'profile.calendar.showFullday': 'Show full day instead of workday (8 - 20:00)',
  'profile.calendar.showWeekends': 'Show weekends in calendar',
  'profile.change_password': 'Change password',
  'profile.codeblocks': 'Theme',
  'profile.codeblocks.settings': 'Code blocks settings',
  'profile.codeblocks.showLineNumbers': 'Show line numbers',
  'profile.codeblocks.showMatchingBraces': 'Show matching braces',
  'profile.codeblocks.showRainbowBrackets': 'Show rainbow brackets',
  'profile.confirm_new_password': 'Confirm new password',
  'profile.current_password': 'Current password',
  'profile.default.password': 'Default password',
  'profile.default.password.description': 'At least 8 characters.  The user will be prompted to change their password on their first login.',
  'profile.default.password.error': 'Password is too weak',
  'profile.default.password.placeholder': 'Password',
  'profile.display_name': 'Display name',
  'profile.endDate': 'End date',
  'profile.errors.change_password': `Couldn't change password`,
  'profile.errors.firstname.length': 'The firstname must be at least 1 character and at most 64 characters long',
  'profile.errors.lastname.length': 'The lastname must be at least 1 character and at most 64 characters long',
  'profile.errors.password.capital': 'The password must contain uppercase and lowercase letters',
  'profile.errors.password.length': 'The password must consist of at least 8 characters',
  'profile.errors.password.number': 'The password must contain at least 1 number',
  'profile.errors.passwords_not_matching': 'The new passwords do not match',
  'profile.errors.passwords_validation': 'The password must contain at least 1 lowercase, 1 uppercase letter and 1 numeric character.',
  'profile.errors.something_went_wrong': "The user's details cannot be shown at the moment",
  'profile.errors.username.length': 'The username must be at least 1 character and at most 64 characters long',
  'profile.errors.username.pattern': 'The username can only contain letters and numbers',
  'profile.errors.wrong_current_password': 'Your current password is incorrect',
  'profile.eula_accept': 'Accept the End User License Agreement',
  'profile.eula_accepted': 'End User License Agreement Accepted',
  'profile.first_name': 'First name',
  'profile.language': 'Language',
  'profile.language.choose': 'Choose your preferred language',
  'profile.last_name': 'Last name',
  'profile.mail': 'E-mail',
  'profile.name': 'Name',
  'profile.navLabel': 'Profile-info',
  'profile.new_password': 'New password',
  'profile.no_mail': 'No e-mail povided',
  'profile.notifications': 'Notifications list',
  'profile.notifications.empty': 'Currently there are no notifications',
  'profile.preferences': 'Preferences',
  'profile.role': 'Role',
  'profile.settings_being_retrieved': 'Your settings are being retrieved, please wait a moment.',
  'profile.slideviewer': 'Slides',
  'profile.slideviewer.viewMode': 'Default view mode',
  'profile.success.change_password': 'Password changed successfully',
  'profile.teams.assignmentAddToCalendarActions': 'For whom do you want this to appear in the Agenda?',
  'profile.teams.assignmentAddToCalendarActions.NONE': 'Nobody',
  'profile.teams.assignmentAddToCalendarActions.STUDENTS_AND_TEAM_OWNERS': 'Students and teachers',
  'profile.teams.assignmentAddToCalendarActions.STUDENTS_ONLY': 'Students',
  'profile.teams.sendTo': 'Send to teams',
  'profile.title': 'Profile',
  'profile.username': 'Username',

  // HelpVideos
  'help_videos.categories': 'Categories',
  'help_videos.categories.sr.link': 'Check out all videos in the category {category}',
  'help_videos.featured': 'Featured',
  'help_videos.title': 'How-to videos',
  'help_videos.videos': 'Videos',
  'help_videos.short.description.title': 'More info ',

  // password strength
  'password-strength.very_weak': 'Very weak password',
  'password-strength.weak': 'Weak password',
  'password-strength.almost_there': 'Almost there',
  'password-strength.good': 'Good password',
  'password-strength.excellent': 'Excellent password',
  'password.dialog.generate-password': 'Generate password',

  // FeedbackPopover
  "feedback-popover.feedback": "Feedback",
  "feedback-popover.placeholder": "Add your feedback here.",

  // ContentSlideViewer
  'slideviewer.error': 'There are no slides for this lesson.',
  'slide_viewer.scroll_up': 'Scroll up',
  'slide_viewer.scroll_down': 'Scroll down',
  'content-slide-viewer.no-slides': 'No slides',
  'content-slide-viewer.no-slides.description': 'No slides seem available for this view mode. Please try another one:',
  'content-slide-viewer.no-slides.description-student': 'This lesson does not seem to contain any slides.',
  'content-slide-viewer.forgot-to-save': 'Forgot to submit?',
  'content-slide-viewer.forgot-to-save-description': 'The answer to this question has not been saved yet. Do you wish to submit?',

  // ContentSlideViewer ExamSlide
  'content-slide-viewer.hard-submit-slide.title': 'Submit',
  'content-slide-viewer.hard-submit-slide.description': 'When you submit the exam, it will no longer be possible to change your answers. Are you sure you want to submit?',
  'content-slide-viewer.hard-submit-slide.submit': 'Submit',
  'content-slide-viewer.hard-submit-slide.submitted': 'Submitted',
  'content-slide-viewer.hard-submit-slide.success': 'The exam has been submitted successfully',
  'content-slide-viewer.hard-submit-slide.error': 'Oops! Something went wrong while submitting the test. Try again',

  // Scratch
  'scratch.header.new_project': 'New project',
  'scratch.header.start_over': 'Start over',
  'scratch.header.upload-exercise': 'Upload exercise',
  'scratch.header.turbo': 'Turbo',
  'scratch.header.by': 'By',
  'scratch.scratch_error': 'Your Scratch exercise could not be loaded...',
  'scratch.confirmation.content': 'Are you sure you want to reset the exercise?',

  // Coach Codi
  'codi.messages.tests_loading': "I'm testing your exercise. This may take some time.",
  'codi.messages.tests_more_than_half_correct': 'More than half of my tests are working already! Good job!',
  'codi.messages.tests_more_all_correct': "All tests passed! You're done here!",
  'codi.messages.test_ready': 'New testresults are waiting for you.',
  'codi.messages.welcome': "Hello, I'm Coach Codi! I will test your code. Press the button to the right to start.",


  // Program usage overview
  'program-usage-overview.chapter': 'Lesson',
  'program-usage-overview.classes': 'Classes',
  'program-usage-overview.classes.filter': 'Filter by class',
  'program-usage-overview.empty': 'Currently, no students are using this program.',
  'program-usage-overview.empty.search': 'No students were found that match these search criteria.',
  'program-usage-overview.name': 'Student',
  'program-usage-overview.no-access': 'You have no access to this program for this period. Please contact an admin if you think this is an error.',
  'program-usage-overview.start': 'Start time',
  'program-usage-overview.teacher': 'Teacher',


  // Student overview
  'student-overview.classes': 'Class groups',
  'student-overview.courses': 'Courses',
  'student-overview.users': 'Users',
  'student-overview.title': 'Students',
  'student-overview.column.name': 'Name',
  'student-overview.column.endDate': 'End date',
  'student-overview.column.status': 'Status',
  'student-overview.column.username': 'Username',
  'student-overview.column.class': 'Class group',
  'student-overview.column.reset-password.header': 'Reset password',
  'student-overview.column.reset-password.info': 'Enter the desired password',
  'student-overview.column.reset-password.repeat': 'Enter the desired password',
  'student-overview.column.reset-password.label': 'New password',
  'student-overview.column.reset-password.label.repeat-password': 'Repeat the new password',
  'student-overview.column.reset-password.confirm': 'Reset',
  'student-overview.column.reset-password.error': 'Error whilst resetting password',
  'student-overview.column.delete-student.success': "User deleted successfully",
  'student-overview.column.delete-student.error': "Something went wrong whilst deleting the student",
  'student-overview.column.edit-student.success': "User edited successfully",
  'student-overview.column.edit-student.error': "Something went wrong whilst editing the student",
  'student-overview.column.reset-password.confirmation': 'New password was copied to your clipboard',
  'student-overview.new-student': "New student",
  'student-overview.add-student': "Add student",
  'student-overview.no-students': "This class group contains no students",
  'student-overview.column.create-student.success': "User created successfully. Password copied to clipboard",
  'student-overview.column.create-student.error': "Something went wrong whilst creating the student",
  'student-overview.are-you-sure': "Are you sure you want to delete this user?",

  // Teacher overview
  'teacher-overview.classes': 'Class groups',

  // classgroups overview
  'classgroup-overview.column.name': 'Name',
  'classgroup-overview.column.archived': 'Archived',
  'classgroup-overview.archived.true': 'Archived',
  'classgroup-overview.archived.false': 'Not archived',
  'classgroup-overview.not-archived': 'Show archived class groups',


  // Organization overview
  'organization-overview.button.add-organization': 'Add organisation',
  'organization-overview.column.issuer': 'Issuer',
  'organization-overview.column.language': 'Language',
  'organization-overview.column.name': 'Name',
  'organization-overview.column.usernamePrefix': 'Prefix',
  'organization-overview.empty': 'No organisations matching these search criteria were found',
  'organization-overview.error': "An error occured while loading the organisations",
  'organization-overview.no-calendar': 'Calendar disabled',
  'organization-overview.not-active': 'Inactive organisations',
  'organization-overview.title': 'Organisations',

  // Users overview
  'users-overview.confirmation-text': "Are you sure you want to delete this user",
  'users-overview.disable': "Disable user",
  'users-overview.enable': "Enable user",
  'users-overview.error': "An error occured while loading the users",
  'users-overview.firstName': "Search by first name",
  'users-overview.lastName': "Search by last name",
  'users-overview.use-disable-text': "We strongly recommend disabling the user. Deleting users is permanent and may lead to data corruption (e.g. broken links), but disabling can still be reversed .",
  'users-overview.username': "Search by username",

  // Program overview
  'program-overview.column.name': "Name",
  'program-overview.column.seatCount': "Seat Count",
  'program-overview.column.seatPeriod': "Seat Period",
  'program-overview.column.type': "Program Type",
  'program-overview.empty': "There are no programs linked to this period for this organization.",
  'program-overview.empty.search': "No programs matching this search criteria were found.",
  'program-overview.error': "An error occured while loading the programs.",
  'program-overview.new-program': "Add program",
  'program-overview.title': "Programs",

  // Chapter overview
  'chapter-overview.search.placeholder': 'Search by name',
  'chapter-overview.select.placeholder': 'Filter by tag',

  // ChapterItem overview
  'chapterItem-overview.search.placeholder': 'Search by name',
  'chapterItem-overview.select.placeholder': 'Filter by tag',

  // Classes overview
  "classes-overview.view.students": "Students",
  "classes-overview.view.lessons": "Lessons",
  "classes-overview.view.exercises": "Exercises",
  "classes-overview.view.tests": "Tests",
  "classes-overview.column.classname": "Name class group",
  "classes-overview.column.students": "Students",
  'classes-overview.error': "An error occured while loading the class group",

  // Class detail
  "class-detail.pageTitle": "Class overview",

  // Students overview
  "students-overview.column.email": "E-mail",
  "students-overview.column.name": "Name",
  "students-overview.column.profiletest": "Profile test",
  "students-overview.column.username": "Username",
  'students-overview.error': "An error occured while loading the students",
  'students-overview.search.email': 'Search e-mail',
  'students-overview.search.username': 'Search username',
  'students-overview.exportResults.COMPLETED': 'Successfully published results to {where} at {dateTime}.',
  'students-overview.exportResults.export': 'Publish to {where}',
  'students-overview.exportResults.FAILED': 'Failed to publish results at {dateTime}.',
  'students-overview.exportResults.isLoading': 'No grades have been sent to {where} yet.',

  // Lesson overview
  'lessons-overview.error': 'An error occured while loading the lessons',
  'lessons-overview.column.startDate': 'Start date',
  'lessons-overview.column.date': 'Date',
  'lessons-overview.column.endDate': 'End date',
  'lessons-overview.column.author': 'Author',
  'lessons-overview.column.type': 'Type',
  'lessons-overview.column.moment': 'Date',
  'lessons-overview.column.chapterItemName': 'Lesson name',
  'lessons-overview.search': 'Search student name',
  'lessons-overview.filterTeacher': 'Filter by teacher',
  'lessons-overview.range': 'Range',
  'lessons-overview.planned_start': 'planned on:',
  'lessons-overview.planned_end': 'planned on:',
  'lessons-overview.at': "at",
  'lessons-overview.from': "from",
  'lessons-overview.until': "until",
  'lessons-overview.title': 'Classresults',

  // Lesson results overview
  'lesson-results-overview-klasgemiddelde': 'Class group average',
  'lesson-results-overview-search': 'Search by class group',
  'lesson-results-class-not-found': 'Class group not found',

  // exercise overview
  'exercises-overview.column.endDate': 'End date',
  'exercises-overview.column.startDate': 'Start date',
  'exercises-overview.column.title': 'Title',
  'exercises-overview.search': 'Search title',
  'exercises-overview.range': 'Range',
  'exercises-overview.planned_start': 'planned on:',
  'exercises-overview.planned_end': 'planned on:',
  'exercises-overview.at': "at",

  // Manage program details
  'manage-program-details.name': "Name",
  'manage-program-details.type': "Program type",
  'manage-program-details.edit': "Edit program",
  'manage-program-details.chapter-item': "Item",
  'manage-program-details.chapter-items': "Items",
  'manage-program-details.no-chapters': "No chapters",
  'manage-program-details.no-chapter-items': "No lessons",
  'manage-program-details.add-chapter': "Add chapter",
  'manage-program-details.edit-chapter': "Edit chapter",
  'manage-program-details.delete-chapter': "Delete chapter",
  'manage-program-details.add-chapter-item': "Add lesson",
  'manage-program-details.delete-chapter-item': "Delete lesson",
  'manage-program-details.edit-chapter-item': "Edit lesson",
  'manage-program-details.error': "An error occured while loading the program.",

  // program dialog
  'program-dialog.color.description': "Will be used for displaying calendar items.",
  'program-dialog.document': 'Link document',
  'program-dialog.document.description': 'Documents related to this program.',
  'program-dialog.weight.description': "Will be used to sort programs.",

  // Add program dialog
  'add-program-dialog.header': "Create new program",
  'add-program-dialog.error': "Something went wrong while creating a new program.",
  'add-program-dialog.name': "Program name",
  'add-program-dialog.type': "Program type",
  'add-program-dialog.add': "Add program",
  'add-program-dialog.color': "Add colour",
  'add-program-dialog.file': "Add file",

  // Update chapter
  "update-chapter-dialog.header": "Update chapter",

  // Link programs
  'link-programs-dialog.header': "Link programs to teacher",
  'link-programs-dialog.confirm': "Confirm",
  'link-programs-dialog.label': "Programs",
  'link-program-dialog.all-programs-linked': 'All programs linked',

  // Unlink programs
  'unlink-programs-dialog.header': "Disconnect teacher from program",
  'unlink-programs-dialog.confirm': "Confirm",
  'unlink-programs-dialog.label': "Programs",
  'unlink-program-dialog.all-programs-unlinked': "All programs disconnected",

  // Add classgroups dialog
  "add-classgroups-dialog.header": "Add user to class group",
  "add-classgroups-dialog.label": "Class groups",
  "add-classgroups-dialog.description": "Not all class groups are shown. Please search for the class group you want to add.",
  "add-classgroups-dialog.confirm": "Confirm",
  "add-classgroups-dialog.placeholder": "Search by name",
  "link-classgroup-dialog.all-classgroups-linked": "All class groups linked",

  // date range dialog
  "date-range-dialog.header": "Select range",
  "date-range-dialog.apply": "Apply",

  // Add or update organization dialog
  'aou-org.add': "Add Organization",
  'aou-org.adminGroupId': "Admin Group ID",
  'aou-org.classNamesToImport': "Class Names to Import",
  'aou-org.classNamesToImport.description': "Add the names of the classes to import. Separated by a comma and a space, for example: 'Class1, Class2, Class3'",
  'aou-org.import.classes': "Import Classes",
  "aou-org.import.clientId": "Client ID",
  "aou-org.import.clientSecret": "Client secret",
  "aou-org.import.membershipOfStudentsToClasses": "Import student memberships to classes",
  "aou-org.import.membershipOfTeachersToClasses": "Membership of teachers to class groups",
  'aou-org.import.one-roster.title': "One Roster data",
  'aou-org.import.students': "Import students",
  'aou-org.import.teachers': "Import teachers",
  'aou-org.issuer': "Sign-in platform",
  'aou-org.language': "Choose the language for the organization",
  'aou-org.name': "Organization name",
  'aou-org.prefix': "Organization prefix",
  'aou-org.prefix-error': "This prefix already exists",
  'aou-org.smartSchoolDomain': "Domain prefix",
  'aou-org.smartSchoolDomain.description': 'If you have a domain: "https://domainprefix.smartschool.be", you must specify the domain prefix',
  'aou-org.studentGroupId': "Student group id",
  'aou-org.submit': "Create organization and add period",
  'aou-org.teacherGroupId': "Teacher group id",
  'aou-org.teamsImportStatus': "Import status",
  'aou-org.tenantId': "Tenant id",
  'aou-org.update': "Edit organization",

  // Activate program dialog
  'activate-organization-dialog.header': "Activate organisation",
  'activate-organization-dialog.error': "Something went wrong while activating the organisation.",
  'activate-organization-dialog.confirm': "Activate",
  'activate-organization-dialog.confirmation-text': 'Are you sure you want to activate the "{organisationName}" organisation?',

  // Deactivate program dialog
  'deactivate-organization-dialog.header': "Deactivate organisation",
  'deactivate-organization-dialog.error': "Something went wrong while deactivanting the organisation.",
  'deactivate-organization-dialog.confirm': "Deactivate",
  'deactivate-organization-dialog.confirmation-text': 'Are you sure you want to deactivate "{organisationName}" organisation?',

  // Add class group dialog
  'add-class.group-dialog.header': "Create new class group",
  'add-class.group-dialog.error': "Something went wrong while creating a new class group.",
  'add-class.group-dialog.confirm': "Create",
  'add-class.group-dialog.name': "Class group name",
  'add-class.group-dialog.add': "Add class group",

  // Delete class group dialog
  'delete-class.group-dialog.header': 'Archive:',
  'delete-class.group-dialog.content': 'Are you sure you want to archive?',

  // Delete program dialog
  'delete-program-dialog.header': "Delete program",
  'delete-program-dialog.error': "Something went wrong while deleting a new program.",
  'delete-program-dialog.confirm': "Delete",
  'delete-program-dialog.confirmation-text': "Are you sure you want to delete this program?",

  // Delete user dialog
  'delete-user-dialog.header': "Delete user",
  'delete-user-dialog.error': "Something went wrong while deleting a user.",
  'delete-user-dialog.confirm': "Delete",
  'delete-user-dialog.confirmation-text': "Are you sure you want to delete this user?",

  // Deactivate user dialog
  'deactivate-user-dialog.header': "Deactivate user",
  'deactivate-user-dialog.confirm': "Deactivate",
  'deactivate-user-dialog.confirmation-text': "Are you sure you want to deactivate {user}?",

  // Link user to class dialog
  'link-user.to.class-dialog.header': "Link user to class group",
  'link-user.to.class-dialog.add': "Link to class group",
  'link-user.to.class-dialog.confirm': "Update",

  // Update program dialog
  'update-program-dialog.color': "Update colour",
  'update-program-dialog.confirm': "Modify organization",
  'update-program-dialog.error': "Something went wrong while updating the program.",
  'update-program-dialog.header': "Edit program",
  'update-program-dialog.name': "Program name",
  'update-program-dialog.type': "Program type",

  // Update class group
  'update-class.group-dialog.confirm': "Update class group",

  // chapter dialog
  'chapter-dialog.document': 'Link document',
  'chapter-dialog.document.description': 'Documents related to this chapter.',
  'chapter-dialog.name': "Chapter name",
  'chapter-dialog.weight.description': "Will be used to sort chapters.",

  // Add chapter dialog
  'add-chapter-dialog.header': "Create new chapter",
  'add-chapter-dialog.error': "Something went wrong while creating a new chapter.",
  'add-chapter-dialog.add': "Add chapter",

  // Delete chapter dialog
  'delete-chapter-dialog.header': "Delete chapter",
  'delete-chapter-dialog.error': "Something went wrong while deleting the chapter.",
  'delete-chapter-dialog.confirm': "Delete",
  'delete-chapter-dialog.confirmation-text': "Are you sure you want to delete this chapter?",

  // chapter item dialog
  'chapter-item-dialog.document': 'Link document',
  'chapter-item-dialog.document.description': 'Documents related to this lesson.',
  'chapter-item-dialog.weight.description': "Will be used to sort lessons.",


  // Add chapter item dialog
  'add-chapter-item-dialog.header': "Add lesson",
  'add-chapter-item-dialog.error': "Something went wrong while adding the lesson.",
  'add-chapter-item-dialog.name': "Lesson name",
  'add-chapter-item-dialog.lesson-content-id': "Lesson content ID",

  // Delete chapter dialog
  'delete-chapter-item-dialog.header': "Delete lesson",
  'delete-chapter-item-dialog.error': "Something went wrong while deleting the lesson.",
  'delete-chapter-item-dialog.confirm': "Delete",
  'delete-chapter-item-dialog.confirmation-text': "Are you sure you want to delete lesson?",

  // Update chapter item dialog
  'update-chapter-item-dialog.header': "Edit lesson",
  'update-chapter-item-dialog.error': "Something went wrong while updating the lesson.",
  'update-chapter-item-dialog.name': "Lesson name",
  'update-chapter-item-dialog.lesson-content-id': "Lesson content ID",

  // Add program to organization
  'add-program-dialog.add-to-organization': "Add program to organisation",
  'add-program-dialog.close': "Close",
  'add-program-dialog.what_are_you_looking_for': 'What are you looking for?',
  'add-program-dialog.seat-count': "Seat Count",
  'add-program-dialog.enter.seat-count': "Please enter seat count.",

  // Edit seats for program dialog
  'edit-seats-for-program.change': "Edit the amount of seats for ",
  'edit-seats-for-program.submit': "Adjust seats",
  'edit-seats-for-program.edit': "Adjust the amount of seats",

  // Edit period for program dialog
  'edit-period-for-program.change': "Edit period for ",
  'edit-period-for-program.submit': "Adjust period",
  'edit-period-for-program.edit': "Adjust period",
  'edit-period-for-program.seatPeriod.choose': "Choose a seat period",

  // Schooladmin overview
  'schooladmin-overview.column.name': 'First name',
  'schooladmin-overview.column.lastname': 'Last name',
  'schooladmin-overview.new-user': 'New {what}',
  'schooladmin-overview.new-class-group': 'New class group',
  'schooladmin-overview.new-schooladmin': 'Add school admin',
  'schooladmin-overview.edit-schooladmin': 'Edit school admin',

  // Schooladmin dialog
  'delete-schooladmin-header': 'Delete school admin',
  'email.validate-error': 'Invalid e-mail format',

  // Schooladmin notifications
  'schooladmin.error': 'Oops, something went wrong.',
  'schooladmin.success.add': 'School admin added successfully.',
  'schooladmin.success.delete': 'School admin deleted successfully.',
  'schooladmin.success.edit': 'School admin edited successfully.',

  // userProfileSchooladmin
  'schooladmin.classes.empty': 'This {what} has not been linked to any class groups.',
  'schooladmin.programs.empty': 'This {what} has not been linked to any programs.',

  // Program detail schooladmin
  'schooladmin.program.detail.empty': 'Currently no chapters have been linked to this program.  Best ask an admin to do so.',

  // Program usage schooladmin
  'schooladmin.program.usage.empty': 'Currently there is no usage of this program.',

  // Program detail schooladmin
  'admin.program.detail.empty': 'Currently no chapters have been linked to this program.',

  // Add student dialog
  'add-user-dialog.header': "Create new {what}",
  'add-user-dialog.success': "Successfully created new student.",
  'add-user-dialog.error': "Something went wrong while creating a new student.",
  'add-user-dialog.confirm': "Create",
  'add-user-dialog.linked_classgroups': "Linked class groups",
  "add-user-dialog.user-type": 'What type of user?',

  // Add teacher dialog
  'add-teacher-dialog.header': "Create new teacher",
  'add-teacher-dialog.success': "Successfully created new teacher",
  'add-teacher-dialog.error': "Something went wrong while creating a new teacher.",
  'add-teacher-dialog.confirm': "Create",
  'add-teacher-dialog.linked_classgroups': "Linked class groups",

  // hack room
  'hack-room.featured': 'Featured',
  'hack-room.more_lessons': 'More lessons',
  'hack-room.what_are_you_looking_for': 'What are you looking for?',
  'hack-room.select': 'Filter by tag',

  // Exercises
  'exercises-overview.title': 'Exercises of',
  'exercises-overview.pretitle': 'Exercises',
  'exercises-overview.my-exercises.title': 'My exercises',
  'exercises-overview.no_exercises': 'No exercises available',
  'exercises-overview.create': 'Create a new exercise',
  'exercises-overview.error': 'An error occured while loading the exercises.',
  'exercises-overview.view_exercises': 'View exercises',
  'exercises-overview.delete_success': 'Successfully deleted exercise',
  'exercises-overview.delete_fail': 'Error while deleting exercise',
  'exercises-overview.toggle_filter': 'Filter',
  'exercises-overview.filter_placeholder': 'Title',
  'exercises-overview.label.filter': 'Filter on title',

  // scratch gui
  'gui.ftrprf.testresult.click_to_test': 'Check your code!',
  'gui.ftrprf.testresult.failed': 'The test failed',
  'gui.ftrprf.testresult.hide_subtests': 'Hide all checks.',
  'gui.ftrprf.testresult.loading': 'Loading',
  'gui.ftrprf.testresult.of': 'Test of: ',
  'gui.ftrprf.testresult.show_subtests': 'Show all checks.',
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Tests',
  'gui.ftrprf.test-tab': 'Coach Codi',
  'gui.ftrprf.test-tab.latest_test': 'Latest testresult',
  'gui.ftrprf.test-tab.loading': 'Checking your code...',
  'gui.ftrprf.test-tab.old_tests': 'Old testresults',
  'gui.ftrprf.contextMenu.hide_blocks': 'Hide blocks',
  'gui.ftrprf.contextMenu.display_blocks': 'Display blocks',

  // excercise versions
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Solution',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Other',

  // Codi
  'assistant.name': "Coach {name}",
  'assistant.always-here-to-help': "Always here to help you.",
  'assistant.chat.need-help': 'Need help? Click here for a hint.',
  'assistant.getToKnow.title': "I'm coach Codi!",
  'assistant.getToKnow.body': "Hello, I am Coach Codi, your coding coach. I can tell you if your exercises were successful and give you tips on what you can do better. You can find me by clicking the ‘Coach CoDi’ tab in the top menu.",
  'assistant.getToKnow.screenshot': 'The Codi tab in Scratch is the last tab, to the right of the sounds tab.',

  // Manage
  'manage.title': "Manage",
  'manage.edit-school': "Edit school",
  'manage.courses': "Courses",

  // personality
  'personality.test.send': "Send personality tests",
  'personality.test.form.send': "Send out tests",
  'personality.test.adults': "Adults",
  'personality.test.children': "Children",

  // groups
  'groups.group': "Group",
  'groups.members': "Members",
  'groups.compose.divide': "Divide groups",
  'groups.compose.type': "What kind of division would you like?",
  'groups.compose.divider.amount': "Amount of groups",
  'groups.compose.divider.create': "Create groups",
  'groups.compose.option.random': "Random composition",
  'groups.compose.option.steams': "STEAMS composition",
  'groups.compose.randomize.members': "Randomise groups",
  'groups.compose.randomize.roles': "Randomise roles",
  'groups.compose.steams.activity.created': 'STEAMS activity',
  'groups.compose.steams.activity.error': 'the STEAMS activity',
  'groups.compose.steams.loading': "Loading data from STEAMS, this takes a" +
    " couple seconds",
  'groups.compose.steams.noiframe': "Your browser doesn't support this feature",
  'groups.compose.save': "Save groups",
  'groups.compose.start': "Start group composition",
  'groups.compose.memberrow.roles': "Choose a role for this student",
  'groups.save.lesson.success': 'Groups',
  'groups.save.lesson.error': 'groups',

  // Results
  'sendResults.success': "Results successfully send to {what}",
  'sendResults.error': "Something went wrong while sending the results to {what}",


  // eventpopup
  'eventpopup.divide_groups': 'Divide groups',
  'eventpopup.delete.past_event': "Can't delete past event",

  // filter
  'filter.clear': 'Clear filters',

  // Logout
  'logout.title': 'Logging you out.',
  'logout.extra': 'Please wait while we log you out on all our platforms...',

  // Reports
  'global.REPORT.singular': 'Report',
  'global.REPORT.plural': 'Reports',
  'reports.SEAT_UPDATE_FREQUENCY': "updates hourly",
  'reports.SEAT_UPDATE_FREQUENCY_EXPLANATION': "This is not live data: the number of used seats is recalculated hourly.",
  'reports.SEAT_USAGE.extra': 'Rapport dat voor per organisatie, per programma het aantal gebruikte seat en het toegelaten seats weergeeft',
  'reports.SEAT_USAGE': 'Seat usage',

  'reports.CALENDAR_ITEM_USAGE': 'Calender Item usage',
  'reports.CALENDAR_ITEM_USAGE.extra': 'Geeft per organisatie weer hoeveel kalender items er zijn per les/module/pakket',

  'reports.PROGRAM_USAGE': 'Programs usage',
  'reports.PROGRAM_USAGE.extra': "Geeft per organisatie, per programma weer hoeveel unieke gebruikers er mogelijks toegang hebben aan de hand van kalender items en het maximum aantal seats.",

  'reports.COMBINED_PROGRAMS_USAGE': 'Packages usage',
  'reports.COMBINED_PROGRAMS_USAGE.extra': 'Geeft per organisatie voor de Pakketten IT&ME en WIJS (1ste en 2de graad) weer hoeveel unieke gebruikers er mogelijks toegang hebben aan de hand van kalender items',

  'reports.STUDENTS_OVERVIEW_ITANDME': 'Studentoverview IT&ME',
  'reports.STUDENTS_OVERVIEW_ITANDME.extra': 'Een lijst van alle unieke leerlingen die mogelijks toegang hebben tot een les van IT&ME (1ste en 2de graad)',

  'reports.STUDENTS_OVERVIEW_WIJS': 'Studentoverview WIJS',
  'reports.STUDENTS_OVERVIEW_WIJS.extra': 'Een lijst van alle unieke leerlingen die mogelijks toegang hebben tot een les van WIJS (1ste en 2de graad)',

  'reports.STUDENTS_PER_LESSON': 'Studentoverview per lesson',
  'reports.STUDENTS_PER_LESSON.extra': 'Overzicht van welke lessen er zijn opgengezet voor welke klassen, hoeveel leerlingen daarin zitten en welke leerkracht dit heeft opengezet',

  'reports.SLIDES_OPENED': 'Opened lessons per user',
  'reports.SLIDES_OPENED.extra': 'Uitgebreide lijst van elke keer een gebruiker een les heeft open gedaan, inclusief timestamp',

  'reports.LOGGED_IN': 'Logins per user',
  'reports.LOGGED_IN.extra': 'Uitgebreide lijst van elke keer dat een gebruiker ingelogd is op het platform',

  'reports.TEACHERS_EXPORT': 'Teachers export',
  'reports.TEACHERS_EXPORT.extra': 'Lijst van alle Leerkrachten en schooladmins, inclusief email en newsletter toestemming',

  // Tags
  'global.TAG.singular': 'Tag',
  'global.TAG.plural': 'Tags',

  // Periods
  'global.period': 'Period',
  'global.periods': 'Periods',
  'periods.active': 'Active',
  'periods.delete-confirmation': 'Are you sure you want to delete this period?',
  'periods.description': 'Description',
  'periods.endDate': 'End date',
  'periods.errors.dates-overlap': "The period can't overlap with an existing period.",
  'periods.errors.startdate-before-enddate': 'Start date should be before endDate.',
  'periods.new': 'New period',
  'periods.new.for': 'New period for',
  'periods.selector.select-period': "Select a period",
  'periods.startDate': 'Start date',
  'periods.submit': 'Create period',

  // Admin move lesson
  'tools-move-lesson.description': `This page allows you to move lessons from one organization to another. Moving has the additional advantage that the slideIDs remain the same and the slidelinks therefore continue to work.`,
  'tools-copy-lesson.description': `This page allows you to copy lessons from one organization to another.`,
  'move-lesson.method': 'Method',
  'move-lesson.method.move': 'Move',
  'move-lesson.method.copy': 'Copy',

  // Link teachers to programs
  'link-teachers-programs.button': 'Link all teachers to all programs',
  'link-teachers-programs.confirmation': 'This action links all current teachers to all available programmes. You can perform this action whenever you have new teachers and/or programmes. Note that if there are exceptions you will need to manually unlink them via the teacher profile.',
  'link-teachers-programs.success': 'All active teachers are successfully linked to all programmes',

  // Multi-screen mode
  'multi-screen-mode.open': 'Open multi-screen mode',
  'multi-screen-mode.current-slide': 'Current slide',
  'multi-screen-mode.no-manual': 'No notes for this slide.',
  'multi-screen-mode.next-slide': 'Next slide',
  'multi-screen-mode.previous-slide': 'Previous slide',
  'multi-screen-mode.end': 'This lesson has come to an end, but your learning will continue.',
  'multi-screen-mode.progress': '{currentIndex} of {length}',
  'multi-screen-mode.make-main-display': 'Make main display',

  // Smartschool
  'smartschool.popup.lessonsLoading': 'The lessons are being loaded...',
  'smartschool.popup.next': 'To step {step}',
  'smartschool.popup.noClasses': 'No classes were found for you. Ask your school admin to link classes.',
  'smartschool.popup.noPrograms': 'No classes were found for you. Ask your school admin to link lessons or create a lesson yourself.',
  'smartschool.popup.selectViewMode.exam': "You've chosen en exam.  This must always be displayed in the classical view.",
  'smartschool.popup.step1': 'Target group',
  'smartschool.popup.step2': 'Lesson',
  'smartschool.popup.step3': 'View',
  'smartschool.popup.target.all.description': 'This link will be accessible to all classes, there is no access restriction.',
  'smartschool.popup.target.all.name': 'All classes',
  'smartschool.popup.target.specific.description': 'Select 1 or more classes that should have access to the lesson via this link.',
  'smartschool.popup.target.specific.name': 'Specific class(es)',
  'smartschool.popup.use': 'Use in Smartschool',

  // Synchroniseer
  'synchronize.checkAll': 'Select all classes',
  'synchronize.clearAll': 'Clear all classes',
  'synchronize.dailySync': 'Sync daily',
  'synchronize.lastSynchronized': 'Last synced',
  'synchronize.navLabel': 'Synchronization navigation',
  'synchronize.noneFound': 'No class groups were found.',
  'synchronize.notSyncedYet': 'Not synced yet.',
  'synchronize.selected': 'Sync selected classes',
  'synchronize.smartschool': 'Synchronize with Smartschool',
  'synchronize.synchronize': 'Sync',
  'synchronize.teams': 'Synchronize with Teams',
  "synchronize.percentage": "Nombre d'utilisateurs traités, environ {percentage}% du total",

  // teams login dialog
  'teams.login.title': 'First connect your Microsoft account',
  'teams.login.explanation': 'In order to use this functionality, you must first link your Microsoft account. After loging in, you will be directed to this page again, where the functionality will be ready for you!',

  // teams error
  "teams.error.synchronize.title": "Unable to synchronize with teams.",
};
