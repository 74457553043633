import { NavLink, useLocation } from 'react-router-dom';
import {
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

import {
  Content,
  DemoInfoBar,
  Header,
  InfoBar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import URLS from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';

function HeaderButton({ href, label }) {
  return (
    <div className="flex items-center justify-center">
      <a
        className="bg-accent-500 text-white rounded text-sm font-medium p-4 py-2 hover:bg-accent-700"
        href={href}
        rel="noreferrer"
        target="_blank"
      >
        {label}
      </a>
    </div>
  );
}

export function SchoolAdminHeader({
  choseActivePeriod,
  currentPeriod,
  hasActivePeriod,
  onPeriodClick,
}) {
  const t = useFormatMessage();

  const user = useContext(UserContext);
  const { demo, isImpersonating, organization } = user;
  const { pathname } = useLocation();

  const container = (children) => (
    <Content hasPaddingBottom={false}>{children}</Content>
  );

  return (
    <>
      <Header
        choseActivePeriod={choseActivePeriod}
        currentPeriod={currentPeriod}
        icons={[
          {
            icon: <PresentationChartBarIcon />,
            label: t('header.navigation.studio'),
            showLabel: true,
            test: dataTestIds.header.studio,
            to: process.env.REACT_APP_STUDIO_URL,
          },
          {
            label: t('header.help'),
            to:
              organization.language?.toLowerCase() === 'nl'
                ? process.env.REACT_APP_HELP_URL
                : process.env.REACT_APP_HELP_EN_URL,
            target: '_blank',
            icon: <QuestionMarkCircleIcon />,
            test: dataTestIds.header.help,
          },
        ]}
        onPeriodClick={onPeriodClick}
        organization={organization}
        profileLinks={[
          {
            label: t('header.profile'),
            to: URLS.PROFILE,
            test: dataTestIds.header.userMenu.links.profile,
          },
          {
            label: t('header.help_videos'),
            to:
              organization.language?.toLowerCase() === 'nl'
                ? process.env.REACT_APP_HELP_URL
                : process.env.REACT_APP_HELP_EN_URL,
            target: '_blank',
            test: dataTestIds.header.userMenu.links.help,
          },
          {
            label: isImpersonating
              ? t('global.unimpersonate')
              : t('header.logout'),
            to: URLS.LOGOUT,
            test: dataTestIds.header.userMenu.links.logout,
          },
        ]}
        user={user}
      >
        <NavLink data-test={dataTestIds.header.manage} to={URLS.MANAGE}>
          {t('header.navigation.manage')}
        </NavLink>

        {organization.idp === 'CODECOSMOS' && (
          <HeaderButton
            href={`${process.env.REACT_APP_CODECOSMOS_URL}api/auth/sign-in?accountType=CODECOSMOS_SCHOOL`}
            label={t('global.buypackages')}
          />
        )}
      </Header>

      {demo && <DemoInfoBar container={container} path={pathname} />}
      {!hasActivePeriod ? (
        <InfoBar
          className="max-w-screen-md"
          container={container}
          text={t('header.no.active.period.studentAndTeacher')}
        />
      ) : null}
    </>
  );
}
