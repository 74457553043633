import classNames from 'classnames';
import { dataTestIds } from '../utils/constants/dataTestIds';
import { logos } from '../utils/constants/logos';

export function Logo({
  className = '',
  organization,
  showOrganization = true,
}) {
  const { idp, name: organizationName } = organization;
  const Dots = organization && logos[idp] ? logos[idp] : logos.FTRPRF;

  return (
    <div
      className={classNames('flex-shrink-0 flex items-center', className)}
      data-test={dataTestIds.component.header.logo}
    >
      <img alt="Future Proof" className="block h-10 w-auto" src={Dots} />
      <div className="ml-2 hidden lg:block">
        <div className="text-gray-800 font-semibold tracking-widest leading-none">
          {/* WHEN YOU ADJUST THIS LOGO, also adjust it in packages/scratch/header */}
          <abbr
            aria-hidden="true"
            className="border-none no-underline"
            title="Future Proof"
          >
            {organizationName ? idp || 'FTRPRF' : ''}
          </abbr>
          <span className="sr-only">{idp || 'Future Proof'}</span>
        </div>
        {showOrganization && (
          <p className="text-accent-500 text-xs tracking-tight leading-none">
            {organizationName}
          </p>
        )}
      </div>
    </div>
  );
}
