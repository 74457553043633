import useFormatMessage from 'hooks/useFormatMessage';
import {
  ResponsiveTable,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';
import { MiniCopyToClipoardButton } from 'components/Buttons/MiniCopyToClipboardButton';
import { getMissingFieldValues } from '../helpers/getMissingFieldValues';

export function MissingFieldsErrorTable({ missingDetailsErrors }) {
  const t = useFormatMessage();

  return (
    <div className="mt-4">
      <h2 className="mt-4 mb-2 text-2xl font-bold">
        {t('errors.category.missing.fields.no.other.errors.title')}
      </h2>
      <p className="mb-4 max-w-[70ch]">
        {t('errors.category.missing.fields.no.other.errors.clarification')}
      </p>
      <ResponsiveTable
        headers={[
          {
            key: 'displayName',
            label: t('profile.display_name'),
            orderable: true,
          },
          {
            key: 'email',
            label: t('global.email'),
            orderable: true,
          },

          {
            key: 'classGroupName',
            label: t('global.CLASSGROUP.name'),
            orderable: true,
          },
          {
            key: 'missingFields',
            label: t('global.missing.values'),
            orderable: true,
          },
        ]}
        mobileBreakpoint={768}
        mobileHeaderWidth={160}
      >
        {missingDetailsErrors.map((detailError) => {
          const {
            classGroupId,
            classGroupName,
            displayName,
            email,
            missingFields,
            userId,
          } = getMissingFieldValues(detailError, t);

          return (
            <ResponsiveTableRow key={detailError.userId}>
              <ResponsiveTableCol>
                {displayName?.value || t('global.unknown')}
                <span className="text-xs text-gray-600 block flex items-center">
                  {userId?.value || t('global.unknown')}
                  <MiniCopyToClipoardButton text={userId?.value} />
                </span>
              </ResponsiveTableCol>
              <ResponsiveTableCol extendClassNames="align-top">
                {email?.value === 'null' || !email?.value
                  ? t('global.unknown')
                  : email.value}
              </ResponsiveTableCol>
              <ResponsiveTableCol>
                {classGroupName?.value || t('global.unknown')}

                <span className="text-xs text-gray-600 block">
                  {classGroupId?.value || t('global.unknown')}
                  <MiniCopyToClipoardButton text={classGroupId?.value} />
                </span>
              </ResponsiveTableCol>
              <ResponsiveTableCol extendClassNames="align-top">
                {missingFields}
              </ResponsiveTableCol>
            </ResponsiveTableRow>
          );
        })}
      </ResponsiveTable>
    </div>
  );
}
