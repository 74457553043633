// below disable because we need it for the div title
/* eslint-disable react/no-danger */
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as sanitizeHtml from 'sanitize-html';

import { DisclosureFeedback, Input } from '@ftrprf/tailwind-components';

import { useTeacherUpdateQuestion } from 'hooks/api/portfolioService/mutations/useTeacherUpdateQuestion';
import useFormatMessage from 'hooks/useFormatMessage';

import { useDebounce } from '@ftrprf/hooks';
import { AnswerToMultipleChoiceQuestion } from './AnswerToMultipleChoiceQuestion';
import { AnswerToOpenQuestion } from './AnswerToOpenQuestion';
import { PeriodsContext } from '../../../../providers/PeriodsProvider';

export function QuestionItem({ index, question, total, updateTotal }) {
  const t = useFormatMessage();
  const { selectedPeriodIsNotActivePeriod } = useContext(PeriodsContext);
  const scoreName = `score${index}`;
  const feedbackName = `feedback${index}`;
  const { earned, feedback, max, questionTitle: title, type } = question;
  const [hasFeedback, setHasFeedback] = useState(Boolean(feedback));
  const [questionFeedback, setQuestionFeedback] = useState(feedback);
  const debouncedQuestionFeedback = useDebounce(questionFeedback, 200);
  const [score, setScore] = useState(earned);
  const [scoreError, setScoreError] = useState([]);
  const { sessionId: lessonSessionId, userId } = useParams();
  const { teacherUpdateQuestionFeedback, teacherUpdateQuestionScore } =
    useTeacherUpdateQuestion();
  const {
    isLoading: updateFeedbackLoading,
    mutateAsync: updateQuestionFeedbackHook,
  } = teacherUpdateQuestionFeedback;
  const { isLoading: updateScoreLoading, mutate: updateQuestionScoreHook } =
    teacherUpdateQuestionScore;
  const { control, reset, setValue } = useForm();

  useEffect(() => {
    const defaultValues = {
      feedbackName: questionFeedback || '',
      scoreName: score || 0,
    };
    reset(defaultValues);
  }, [
    index,
    feedbackName,
    scoreName,
    feedback,
    earned,
    reset,
    score,
    questionFeedback,
  ]);

  const updateQuestionFeedback = useCallback(
    async (value) => {
      if (value === feedback) return;
      const input = {
        lessonSessionId,
        userId,
        input: {
          earned: score || 0,
          feedback: value,
          max: question?.max,

          questionId: question?.questionId,
        },
      };
      await updateQuestionFeedbackHook(input);
      setHasFeedback(Boolean(value));
    },
    [
      feedback,
      lessonSessionId,
      question?.max,
      question?.questionId,
      score,
      updateQuestionFeedbackHook,
      userId,
    ],
  );

  useEffect(() => {
    void updateQuestionFeedback(debouncedQuestionFeedback);
  }, [debouncedQuestionFeedback, updateQuestionFeedback]);

  const questionScoreChangeHandler = useCallback(
    async (value) => {
      const errors = [];
      const updateQuestionScore = async (value) => {
        const input = {
          lessonSessionId,
          userId,
          input: {
            earned: value,
            feedback: question?.feedback,
            max: question?.max,
            questionId: question?.questionId,
          },
        };
        await updateQuestionScoreHook(input);
        updateTotal(total - score + value);
      };

      setScoreError(errors);
      const numberValue = Number(value.replace(',', '.'));
      if (numberValue === score) return;
      if (!value?.length) errors.push(t('global.required-field'));
      if (!/[+-]?\d+(?:[.,]\d+)?/.test(value))
        errors.push(t('global.required-number'));
      if (numberValue > max || numberValue < 0)
        errors.push(t('results_overview.score.between', { min: 0, max }));

      if (errors.length) {
        setScoreError(errors);
        return;
      }

      setScore(numberValue);
      setValue(`score${index}`, numberValue, { shouldTouch: true });
      await updateQuestionScore(numberValue);
    },
    [
      index,
      lessonSessionId,
      max,
      question?.feedback,
      question?.max,
      question?.questionId,
      score,
      setValue,
      t,
      total,
      updateQuestionScoreHook,
      updateTotal,
      userId,
    ],
  );

  // eslint-disable-next-line react/no-danger
  const sanitizedTitleDiv = (
    <div
      className="font-semibold"
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }}
    />
  );

  return (
    <li
      className={`${
        index !== 0 ? 'border-t border-t-neutral-100 ' : ''
      } border-b border-b-neutral-100 py-8`}
    >
      <article className="flex mb-6">
        <div className="mr-8 text-right w-[7rem] min-w-[7rem]">
          <div className="text-neutral-400 uppercase whitespace-nowrap">
            {t('student-answers.question_label')} {index + 1}
          </div>
          <Controller
            control={control}
            name={scoreName}
            render={({ field: { onBlur } }) => (
              <div className="flex flex-col">
                <div className="mt-6 flex items-center justify-end">
                  <Input
                    key={scoreName}
                    autoComplete="off"
                    className="bg-neutral-100 p-1 rounded text-xs max-w-[4rem]"
                    defaultValue={score}
                    disabled={
                      updateScoreLoading || selectedPeriodIsNotActivePeriod
                    }
                    hasErrors={Boolean(scoreError.length)}
                    id={scoreName}
                    onBlur={(e) => {
                      onBlur(e);
                      void questionScoreChangeHandler(e.target.value);
                    }}
                    onMouseLeave={(e) => {
                      onBlur(e);
                      void questionScoreChangeHandler(e.target.value);
                    }}
                    type="text"
                  />{' '}
                  / <span className="text-neutral-600">{max}</span>
                </div>
                {Boolean(scoreError.length) && (
                  <ul
                    className={`text-red-500 text-xs mt-1 text-right ${
                      scoreError.length > 1 ? 'list-disc' : 'list-none'
                    }`}
                  >
                    {scoreError.map((error, index) => (
                      <li key={`score-error${index + 1}`}>{error}</li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            type="input"
          />
        </div>
        <div className="grow">
          {sanitizedTitleDiv}
          {type === 'OPEN' && (
            <AnswerToOpenQuestion
              answer={question.userAnswer}
              exampleAnswer={question?.exampleAnswer}
            />
          )}
          {type === 'MULTIPLE_CHOICE' && (
            <AnswerToMultipleChoiceQuestion
              answers={question?.answers}
              questionIndex={index}
              userAnswers={question?.userAnswers}
            />
          )}
          <DisclosureFeedback
            className="ml-4 text-right min-w-max"
            hasFeedback={hasFeedback}
            test={`feedback${index}`}
            title={t('results_overview.questions.feedback')}
          >
            <Controller
              control={control}
              name={feedbackName}
              render={({ field: { onBlur } }) => (
                <div className="mt-4 max-w-[70ch] grow">
                  <Input
                    key={feedbackName}
                    className="bg-neutral-100 p-1 rounded w-full"
                    defaultValue={questionFeedback}
                    disabled={
                      updateFeedbackLoading || selectedPeriodIsNotActivePeriod
                    }
                    id={feedbackName}
                    onBlur={(e) => {
                      onBlur(e);
                      setQuestionFeedback(e.target.value);
                    }}
                    onMouseLeave={(e) => {
                      onBlur(e);
                      setQuestionFeedback(e.target.value);
                    }}
                    rows={4}
                    type="multiline"
                  />
                </div>
              )}
              type="textarea"
            />
          </DisclosureFeedback>
        </div>
      </article>
    </li>
  );
}
