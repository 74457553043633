import { intersection } from 'lodash-es';

function getIdsFromClassGroupArray(classGroupArray) {
  return classGroupArray.map((classGroup) => classGroup.id);
}

export const getStudentIndex = (students, userId) =>
  students.findIndex((student) => student.id === userId);

export function getFirstMatchingClassgroup(
  classGroupsForCalendarItem = [],
  classGroupsForStudent = [],
) {
  const classgroupIdsForCalendarItem = getIdsFromClassGroupArray(
    classGroupsForCalendarItem,
  );
  const classgroupIdsForStudent = getIdsFromClassGroupArray(
    classGroupsForStudent,
  );
  const idsPresentInBothArrays = intersection(
    classgroupIdsForCalendarItem,
    classgroupIdsForStudent,
  );

  if (idsPresentInBothArrays.length)
    return classGroupsForCalendarItem.filter(
      (classGroup) => classGroup.id === idsPresentInBothArrays[0],
    );

  return classGroupsForCalendarItem[0];
}
