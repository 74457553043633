import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import xor from 'lodash-es/xor';

import { useEffect, useState } from 'react';
import c from '../../utils/c';
import { SearchBar } from '../SearchBar';

export function Filter({
  activeFilters,
  allowNoneSelected = false,
  displayOptionFn,
  name = 'Filter',
  options,
  placeholderForFilter = '',
  searchFilterFunction = null,
  setFilter,
  test = '',
  withSearch = false,
}) {
  const [searchString, setSearchString] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (withSearch) {
      setFilteredOptions(searchFilterFunction(searchString.toLowerCase()));
    }
  }, [searchFilterFunction, searchString, withSearch]);

  return (
    <Popover className="relative inline-block text-left" data-test={test}>
      <Popover.Button
        as="div"
        className="inline-flex justify-center rounded border border-gray-300 shadow-sm items-center px-2 py-1.5 bg-white font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-accent-500"
      >
        {name}
        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-2 h-5 w-5" />
      </Popover.Button>

      <Popover.Panel className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {withSearch && (
            <div className="px-4 py-2 text-sm text-gray-900">
              <SearchBar
                data-test="filter_searchString"
                defaultValue={searchString}
                onChange={setSearchString}
                placeholder={placeholderForFilter}
              />
            </div>
          )}

          {filteredOptions.map((option) => (
            <label
              key={option}
              className={c('text-gray-900 block px-4 py-2 text-sm')}
            >
              <input
                checked={activeFilters.includes(option)}
                className="focus:ring-accent-500 h-4 w-4 text-accent-600 border-gray-300 rounded mr-2"
                data-test={`${test}-option`}
                onChange={() => {
                  const newFilters = xor(activeFilters, [option]);
                  if (newFilters.length > 0 || allowNoneSelected) {
                    setFilter(newFilters);
                  }
                }}
                type="checkbox"
              />
              {displayOptionFn ? displayOptionFn(option) : option}
            </label>
          ))}
        </div>
      </Popover.Panel>
    </Popover>
  );
}
