export function validateEndTimeHours(
  endTimeHour,
  endTimeMinute,
  startTimeHour,
  startTimeMinute,
) {
  if (startTimeHour && endTimeHour && endTimeHour?.label < startTimeHour?.label)
    return false;
  return !(
    endTimeMinute &&
    startTimeMinute &&
    endTimeHour?.label === startTimeHour?.label &&
    endTimeMinute?.label < startTimeMinute?.label
  );
}
