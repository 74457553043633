import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { createRef } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { CalendarButtonIcon } from '../CalendarButtonIcon';

export function DateForLesson({ control, getValues, today }) {
  const t = useFormatMessage();
  const refButtonDate = createRef();

  return (
    <div>
      <p className="mb-1" id="formCalendarItem_datepicker">
        {t('calendar.modal.dateEvent')}
      </p>
      <Controller
        control={control}
        defaultValue={getValues('dateEvent') || today}
        name="dateEvent"
        render={({ field: { onBlur, onChange, value } }) => (
          // todo: make this accessible, will involve a lot of commits in the github repo
          <DatePicker
            autoFocus={false}
            calendarStartDay={1}
            customInput={
              <CalendarButtonIcon
                ref={refButtonDate}
                className="w-48"
                icon={
                  <CalendarIcon
                    aria-hidden="true"
                    className="-ml-0.5 mr-2 h-4 w-4"
                  />
                }
                value={value}
              />
            }
            dateFormat="dd/MM/yyyy"
            onBlur={onBlur}
            onChange={onChange}
            selected={value}
            showPopperArrow={false}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('dropdown.no_results'),
          },
        }}
        type="select"
      />
    </div>
  );
}
