/* eslint-disable react/self-closing-comp */
import { useCallback, useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import useFormatMessage from 'hooks/useFormatMessage';

import { UserContext } from 'providers/UserProvider';
import Dots from '../../assets/vectors/dots.svg';

function Loader(props) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      x="0"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0"
      {...props}
    >
      <path
        d="M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50"
        fill="#4b5563"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="1s"
          from="0 50 50"
          repeatCount="indefinite"
          to="360 50 50"
          type="rotate"
        ></animateTransform>
      </path>
    </svg>
  );
}

export default function Logout() {
  const { isImpersonating } = useContext(UserContext);
  const { instance } = useMsal();
  const t = useFormatMessage();

  if (isImpersonating) {
    localStorage.clear();
    window.location = process.env.REACT_APP_AD_REDIRECT_LINK;
  }

  const logout = useCallback(async () => {
    // No harm in cleaning everything out on logout, this will also make sure old accounts
    // get cleaned from cache and accounts will not return old ones anymore.
    // START Cleanup
    localStorage.clear();
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
    // END Cleanup
    await instance.logoutRedirect();
  }, [instance]);

  const eventHandler = function (e) {
    if (e.data == 'loggedout') {
      logout();
    }
  };

  useEffect(() => {
    // log people out in 3s anyway
    const timeout = setTimeout(logout, 3000);

    // log out when studio is logged out
    window.addEventListener('message', eventHandler);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('message', eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full min-h-full flex items-center justify-center flex-col">
      <img alt="Future Proof" className="block h-16 w-auto mb-8" src={Dots} />
      <h1 className="text-xl text-gray-900 uppercase tracking-wider">
        {t('logout.title')}
      </h1>
      <p className="text-gray-600 mb-4">{t('logout.extra')}</p>
      <Loader className="w-12 h-12" />
      <iframe
        frameBorder="0"
        src={`${process.env.REACT_APP_STUDIO_URL}/logout.html`}
      />
    </div>
  );
}
