import { Controller } from 'react-hook-form';
import { Dropdown } from '@ftrprf/tailwind-components';
import { EmptyState } from 'components/EmptyState';
import useFormatMessage from 'hooks/useFormatMessage';
import { listToOptions } from 'utils/listToOptions';

export function SelectClass({ classes, formOptions, setSelectedClass }) {
  const { control, errors } = formOptions;
  const t = useFormatMessage();

  if (!Boolean(classes?.length)) {
    return (
      <EmptyState imageClass="w-32" textKey="smartschool.popup.noClasses" />
    );
  }

  return (
    <div>
      <Controller
        control={control}
        defaultValue={[]}
        name="classGroups"
        render={({ field: { onBlur, onChange, value } }) => (
          <Dropdown
            inputId="formCalendarItem_selectClass"
            isMulti
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e);
              setSelectedClass(e);
            }}
            options={listToOptions(classes)}
            placeholder={t('calendar.modal.selectClass')}
            returnMethod="object"
            value={[...value]}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('side-calendar-classes.no-classes'),
          },
        }}
        type="select"
      />
      {errors.classGroups && (
        <p className="text-xs text-red-600">{errors.classGroups.message}</p>
      )}
    </div>
  );
}
