import { getCurrentMinutes, getHour } from 'utils/timeHelpers';
import { TeamsAssignmentAddToCalendarActionOptions } from 'utils/constants/teams';

export function setDefaultValues(
  setValue,
  itemInfo,
  isTeams,
  sendToTeams,
  assignmentAddToCalendarAction,
) {
  const today = new Date();
  const minutes = getCurrentMinutes(itemInfo, today);

  setValue('program', undefined);
  setValue('chapter', undefined);
  setValue('chapterItem', undefined);
  setValue('classGroups', undefined);
  setValue('dateEvent', undefined);
  setValue('startTimeHour', getHour(itemInfo, today));
  setValue('startTimeMinute', minutes);
  setValue('endTimeHour', getHour(itemInfo, today, 1));
  setValue('endTimeMinute', minutes);
  setValue('closeAfterEndTime', false);
  setValue('idEvent', false);
  setValue('sendToTeams', sendToTeams || isTeams);
  setValue('resourceDisplayName', undefined);
  setValue('assignmentDescription', undefined);
  setValue(
    'assignmentAddToCalendarAction',
    assignmentAddToCalendarAction ||
      TeamsAssignmentAddToCalendarActionOptions.NONE,
  );
  setValue('assignmentTitle', '');
}
