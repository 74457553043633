/* eslint-disable camelcase */
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { IDPS } from 'utils/constants/idp';
import URLS from 'utils/constants/urls';
import getGenericSessionId from 'utils/getGenericSessionId';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export default function CodeFeverLanding({ account, inProgress }) {
  // ?session_token=913eae5d047c80b32578982126b8edf2&target_link_uri=3700/PROJECTION
  // of
  // ?id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6IkxzTEhMTl8tbmUwandFWkRpLThYY0t4ZzB5b0ZkTHFtd3dLb3QwUUZxdXciLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL2Z0cnByZnRzdGIyYy5iMmNsb2dpbi5jb20vYTc2MTI3ZTctZjBhOS00ZTVjLWIyYjgtOGMzYjU2NDc3ZGI2L3YyLjAvIiwic3ViIjoiYWUxZmVjZTYtM2FmYS00ZDQwLWIyZDgtOGY4NTc5NjhkNzRmIiwiYXVkIjoiMjNhYWNjOGMtODQyZi00ODJhLTlmOWYtNjRjMjViZWM1YmJlIiwiZXhwIjoxNjk0NjAxNTI2LCJhY3IiOiJiMmNfMWFfNGFfM18yXzFfcmVseWluZ3BhcnR5X3VzZXJuYW1lX3NpZ25pbiIsImlhdCI6MTY5NDU5NzkyNiwiYXV0aF90aW1lIjoxNjk0NTk3OTI2LCJpZHBfdXNlcmlkIjoiMTI5IiwiZmlyc3RuYW1lIjoiRmllbiIsImxhc3RuYW1lIjoiU3ByaWV0IiwiZGlzcGxheW5hbWUiOiJGaWVuIFNwcmlldCIsImlkcCI6IkNPREVGRVZFUiIsImlkcF9wbGF0Zm9ybSI6IkNvZGVGZXZlck9yZ2FuaXphdGlvbiIsInVzZXJuYW1lIjoiZmllbnNwcmlldCIsImxhbmd1YWdlIjoibmwiLCJsYXVuY2hfdXJpX3JlZGlyZWN0X3VybCI6IjIyMDIvQ0xBU1NJQ0FMIiwib3JnYW5pemF0aW9uaWQiOiIyNTQiLCJyb2xlcyI6WyJGVFJQUkZfU0NIT09MVEVBQ0hFUiJdLCJuYmYiOjE2OTQ1OTc5MjZ9.fzBM8y-POzvbYUI5dGF_8C9TgmA6TbY8zLWCqaL4dnDNAwEhCLNZCmLXd3qib7mH5N9sIX7DrAcltlWUfkOj0k7k6lt-uBxyiyg-9AmTij2HtFcGmZknqVT89wC6ynNnkHRBOyf-QQU_D0wnnweNqvCNvdpKeRq3aBLuGKUsYhpWWR4l4v97NcVCjvPlHyQcONQzSBmdU1NdfQuQNfKNKDEU18EARFCRZlqynDMy3tkDbQ1A4Gn_9WtiLk475hrsMf_8rlGiqgwYxl36M-dr0pCU0MtuY-72BxNGXj6v-xyU48sNHmaLMPG_gIFYrk2ZGLN6ThBxNz8oAm2uAyJxSQ

  const [searchParams] = useSearchParams();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [alert, setAlert] = useState('');

  const id_token = parseJwt(searchParams.get('id_token'));
  const session_token = searchParams.get('session_token');
  const target_link_uri = searchParams.get('target_link_uri');

  function redirectToCodeFeverAuth() {
    if (session_token && target_link_uri) {
      window.location = `${process.env.REACT_APP_CODEFEVER_SERVICE_INIT_URL}?session_token=${session_token}&target_link_uri=${target_link_uri}`;
    } else {
      console.error('Codefever page without the right params?');
    }
  }

  function redirectToLesson(target_link_uri) {
    if (!target_link_uri) {
      console.error('Codefever page without the right params?');
    } else {
      const [studioId, viewMode] = target_link_uri.split('/');

      navigate(
        generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID, {
          studioId,
          viewMode,
          sessionId: getGenericSessionId(studioId),
        }),
      );
    }
  }

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (!account) {
        /*
        NO USER FOUND
        redirect
      */
        if (id_token) {
          console.log('no user, token, loginredirect');
          instance
            .loginRedirect()
            .catch(() => instance.handleRedirectPromise());
        } else {
          console.log('no user, redirect');
          redirectToCodeFeverAuth();
        }
      }

      if (account) {
        if (account.idTokenClaims?.idp === IDPS.CODEFEVER) {
          /*
          CODEFEVER USER FOUND
          Lets go to the lesson...
        */
          console.log('user, slideviewer');
          const { launch_uri_redirect_url } = id_token || {};
          redirectToLesson(target_link_uri || launch_uri_redirect_url);
        } else {
          /*
          USER FOUND, BUT NOT CODEFEVER
          Logout and redirect
        */
          console.log('user, logout, redirect');
          setAlert(
            'Je bent nog ingelogd als een niet CodeFever gebruiker, log eerst uit.',
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, inProgress]);

  return (
    <div className="p-4 text-center">
      {alert ? <p>{alert}</p> : <p>We laden je les...</p>}
    </div>
  );
}
