import { errorItemKeys } from 'utils/constants/errorItemKeys';

export function getMissingFieldValues(error, t) {
  const { context, errorCategoryName } = error;
  const indexOfMissing = errorCategoryName.indexOf('MISSING');

  return {
    classGroupId: context?.find((item) =>
      item.key.includes(errorItemKeys.CLASSGROUP_ID),
    ),
    classGroupName: context?.find((item) =>
      item.key.includes(errorItemKeys.CLASSGROUP_NAME),
    ),
    displayName: context?.find((item) =>
      item.key.includes(errorItemKeys.DISPLAY_NAME),
    ),
    email: context?.find((item) => item.key.includes(errorItemKeys.EMAIL)),
    missingFields:
      indexOfMissing !== -1
        ? errorCategoryName
            .slice(indexOfMissing + 8)
            .replaceAll('_', ' ')
            .toLowerCase()
        : t('global.unknown'),
    userId: context?.find((item) => item.key.includes(errorItemKeys.USER_ID)),
  };
}
