import { useCallback, useMemo, useState } from 'react';

import useAdminActivateOrganization from 'hooks/api/plannerService/admin/mutations/useAdminActivateOrganization';
import useAdminCreateOrganization from 'hooks/api/plannerService/admin/mutations/useAdminCreateOrganization';
import useAdminDeactivateOrganization from 'hooks/api/plannerService/admin/mutations/useAdminDeactivateOrganization';
import useAdminUpdateOrganization from 'hooks/api/plannerService/admin/mutations/useAdminUpdateOrganization';

import useAdminOrganizations from 'hooks/api/plannerService/admin/queries/useAdminOrganizations';
import { filterOperation } from 'utils/constants/filter';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';
import ActivateOrganizationDialog from './partials/ActivateOrganizationDialog';
import DeactivateOrganizationDialog from './partials/DeactivateOrganizationDialog';
import ManageOrganizations from './ManageOrganizations';
import { EditPeriodDialog } from '../ManagePeriods/partials/EditPeriodDialog';
import { AddOrUpdateOrganizationDialog } from './partials/AddOrUpdateOrganizationDialog';

export default function ManageOrganizationsContainer() {
  const [isAddOrganizationModalOpen, setIsAddOrganizationModalOpen] =
    useState(false);
  const [isAddPeriodDialogModalOpen, setIsAddPeriodDialogModalOpen] =
    useState(false);
  const [isEditorganizationModalOpen, setIsEditOrganizationModalOpen] =
    useState(false);
  const [isActivateOrganizationModalOpen, setIsActivateOrganizationModalOpen] =
    useState(false);
  const [createdOrganization, setCreatedOrganization] = useState(null);

  const [
    isDeactivateOrganizationModalOpen,
    setIsDeactivateOrganizationModalOpen,
  ] = useState(false);
  const [organizationData, setOrganizationData] = useState();

  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isNotActiveChecked, setIsNotActiveChecked] = useState(false);
  const [isShowCalendarChecked, setIsShowCalendarChecked] = useState(false);
  const [sort, setSort] = useState();

  const debouncedFilter = useDebounce(filter, 300);

  const {
    data: organizationsData,
    isError,
    isFetching: isFetchingOrganizations,
    isLoading: isLoadingOrganizations,
    refetch,
  } = useAdminOrganizations({
    filter: [
      {
        key: 'name',
        value: debouncedFilter,
        operation: filterOperation.LIKE,
      },
      {
        key: 'active',
        value: !isNotActiveChecked,
        operation: filterOperation.EQUAL,
      },
      {
        key: 'showCalendar',
        value: !isShowCalendarChecked,
        operation: filterOperation.EQUAL,
      },
    ],
    page: page - 1,
    size: itemsPerPage,
    sort,
  });

  const organizations = organizationsData?.content;
  const pages = organizationsData?.pages;
  const totalItems = organizationsData?.total;

  const isLoading = useMemo(
    () => isLoadingOrganizations || isFetchingOrganizations,
    [isFetchingOrganizations, isLoadingOrganizations],
  );

  const { mutateAsync: adminCreateOrganization } = useAdminCreateOrganization();
  const { mutateAsync: adminUpdateOrganization } = useAdminUpdateOrganization();
  const { mutateAsync: activeOrganization } = useAdminActivateOrganization();
  const { mutateAsync: deactiveOrganization } =
    useAdminDeactivateOrganization();

  const alreadyExistPrefixes = useMemo(
    () => organizations?.map((item) => item.prefix),
    [organizations],
  );

  const addOrganization = async (data) => {
    const { adminCreateOrganization: createdOrganization } =
      await adminCreateOrganization(data);
    setIsAddPeriodDialogModalOpen(true);
    setCreatedOrganization(createdOrganization);
    refetch();
  };

  const editOrganization = async (data) => {
    await adminUpdateOrganization({
      id: organizationData?.id,
      input: data,
    });
    refetch();
  };

  const enableOrganization = async () => {
    await activeOrganization({ id: organizationData?.id });
    refetch();
  };

  const disableOrganization = async () => {
    await deactiveOrganization({ id: organizationData?.id });
    refetch();
  };

  const handleShowInactiveClick = useCallback(
    (value) => {
      setIsNotActiveChecked(value);
      setPage(1);
    },
    [setPage, setIsNotActiveChecked],
  );

  const handleShowCalendarClick = useCallback(
    (value) => {
      setIsShowCalendarChecked(value);
      setPage(1);
    },
    [setPage, setIsShowCalendarChecked],
  );

  return (
    <>
      <AddOrUpdateOrganizationDialog
        alreadyExistPrefixes={alreadyExistPrefixes}
        dismissDialog={() => setIsAddOrganizationModalOpen(false)}
        isAddOrganization
        isOpen={isAddOrganizationModalOpen}
        submitDialog={addOrganization}
      />
      <EditPeriodDialog
        dismiss={() => {
          setIsAddPeriodDialogModalOpen(false);
          setCreatedOrganization(null);
        }}
        existingPeriods={[]}
        isDefaultPeriodForOrganization
        isOpen={isAddPeriodDialogModalOpen}
        organization={createdOrganization}
        organizationId={createdOrganization?.id}
      />

      {organizationData && (
        <AddOrUpdateOrganizationDialog
          dismissDialog={() => setIsEditOrganizationModalOpen(false)}
          isOpen={isEditorganizationModalOpen}
          organizationData={organizationData}
          setOrganizationData={setOrganizationData}
          submitDialog={editOrganization}
        />
      )}

      <ActivateOrganizationDialog
        isOpen={isActivateOrganizationModalOpen}
        onConfirm={enableOrganization}
        onDismiss={() => setIsActivateOrganizationModalOpen(false)}
        organizationName={organizationData?.name}
      />

      <DeactivateOrganizationDialog
        isOpen={isDeactivateOrganizationModalOpen}
        onConfirm={disableOrganization}
        onDismiss={() => {
          setIsDeactivateOrganizationModalOpen(false);
        }}
        organizationName={organizationData?.name}
      />

      <ManageOrganizations
        currentPage={page}
        handleShowCalendarClick={handleShowCalendarClick}
        handleShowInactiveClick={handleShowInactiveClick}
        isError={isError}
        isLoading={isLoading}
        isNotActiveChecked={isNotActiveChecked}
        isShowCalendarChecked={isShowCalendarChecked}
        itemsPerPage={itemsPerPage}
        openActivateModal={() => setIsActivateOrganizationModalOpen(true)}
        openAddModal={() => setIsAddOrganizationModalOpen(true)}
        openDeactivateModal={() => setIsDeactivateOrganizationModalOpen(true)}
        openEditModal={() => setIsEditOrganizationModalOpen(true)}
        organizations={organizations ?? []}
        setFilter={(value) => {
          setPage(1);
          setFilter(value);
        }}
        setItemsPerPage={setItemsPerPage}
        setOrganizationData={setOrganizationData}
        setPage={setPage}
        setSort={setSort}
        sort={sort}
        totalItems={totalItems}
        totalPages={pages}
      />
    </>
  );
}
