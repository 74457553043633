import { useContext, useState } from 'react';

import { UserContext } from 'providers/UserProvider';

import { ResultsForStudent } from './ResultsForStudent';
import { ResultsForTeacher } from './ResultsForTeacher';

export function StudentResultsForLesson() {
  const { isTeacher } = useContext(UserContext);
  const [searchParams] = useState(location.search);

  return isTeacher ? (
    <ResultsForTeacher searchParams={searchParams} />
  ) : (
    <ResultsForStudent />
  );
}
