import { StepItem } from './StepItem';

/**
 * See https://tailwindui.com/components/application-ui/navigation/steps > Panels to see whence the basic code comes.
 * Links were changed into buttons for our use cases as that made more sense for accessibility.
 */
export function Steps({
  className = '',
  currentStepIndex = 0,
  id,
  setCurrentStepIndex,
  steps = {},
  test = '',
}) {
  return (
    <nav aria-label={`Progress_${id}`} className={className} data-test={test}>
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIndex) => (
          <StepItem
            key={step.name}
            isComplete={currentStepIndex > stepIndex}
            isCurrentStep={currentStepIndex === stepIndex}
            isNotLastStep={stepIndex !== steps.length - 1}
            onClick={
              step.disabled ? null : () => setCurrentStepIndex(stepIndex)
            }
            step={step}
          />
        ))}
      </ol>
    </nav>
  );
}
