import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import AddUserToClassDialog from 'pages/ManageSchoolAdmin/ManageUsers/partials/AddUserToClassDialog';

import { ResetPasswordDialog } from 'components/ResetPasswordDialog';

import { UserContext } from 'providers/UserProvider';

import useUserClassgroupManagement from 'hooks/api/plannerService/CRUD/useUserClassgroupManagement';
import useTeacherResetStudentPassword from 'hooks/api/plannerService/teacher/mutations/useTeacherResetStudentPassword';
import useTeacherStudentsByClassId from 'hooks/api/plannerService/teacher/queries/useTeacherStudentsByClassId';

import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';

import StudentsOverview from './StudentsOverview';

export default function StudentsOverviewContainer() {
  // Final items
  const { classGroupId } = useParams();

  // State
  const [filter, setFilter] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ firstName: sortOperation.ASC });
  const [isAddUserToClassDialogOpen, setIsAddUserToClassDialogOpen] =
    useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [studentData, setStudentData] = useState();

  const debouncedFilter = useDebounce(filter, 300);

  const { isTeacher } = useContext(UserContext);

  const {
    data: studentsData,
    isLoading,
    refetch,
  } = useTeacherStudentsByClassId({
    classId: classGroupId,
    filter: [
      {
        key: 'fullname',
        value: debouncedFilter,
        operation: filterOperation.LIKE,
      },
    ],
    page: page - 1,
    size: itemsPerPage,
    sort: { fullname: sortOperation.ASC },
  });
  const students = studentsData?.content;
  const pages = studentsData?.pages;
  const currentPage = studentsData?.currentPage;
  const totalItems = studentsData?.total;

  const { add: useAddUserToClassGroup } = useUserClassgroupManagement();
  const { mutateAsync: AddUserToClassGroup } = useAddUserToClassGroup();

  const addUserToClassGroup = async (data) => {
    await AddUserToClassGroup({
      classGroupId: data.id,
      studentId: studentData.id,
    });
    refetch();
  };

  const { isLoading: isResetting, mutateAsync: resetPassword } =
    useTeacherResetStudentPassword();

  useEffect(() => {
    refetch();
  }, [sort, debouncedFilter, page, filter, refetch]);

  return (
    <>
      {isResetPasswordDialogOpen && (
        <ResetPasswordDialog
          isOpen={isResetPasswordDialogOpen}
          isResetting={isResetting}
          onDismiss={() => setIsResetPasswordDialogOpen(false)}
          resetPassword={(confirmationPassword) => {
            resetPassword({
              studentId: studentData?.id,
              newPassword: confirmationPassword,
            });
          }}
        />
      )}
      {isAddUserToClassDialogOpen && (
        <AddUserToClassDialog
          isOpen={isAddUserToClassDialogOpen}
          onConfirm={(data) => {
            addUserToClassGroup(data);
            setIsAddUserToClassDialogOpen(false);
          }}
          onDismiss={() => setIsAddUserToClassDialogOpen(false)}
          userId={studentData?.id}
        />
      )}
      <StudentsOverview
        canResetPassword={isTeacher}
        currentPage={currentPage + 1}
        isLoading={isLoading}
        itemsPerPage={itemsPerPage}
        openResetModal={() => setIsResetPasswordDialogOpen(true)}
        pages={pages}
        setFilter={(value) => {
          setPage(1);
          setFilter(value);
        }}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        setSort={setSort}
        setStudentData={setStudentData}
        sort={sort}
        students={students}
        totalItems={totalItems}
      />
    </>
  );
}
