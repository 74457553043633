// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import { Error, Input, Label } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';

export function OneRosterFields({ errors, register }) {
  const t = useFormatMessage();

  return (
    <fieldset className="border border-solid border-gray-300 p-4 flex flex-col gap-4">
      <legend className="font-semibold text-xs p-1">
        {t('aou-org.import.one-roster.title')}
      </legend>

      <div className="flex flex-col">
        <Label htmlFor="aouOrganizationDialog-oneRoster-clientId">
          {t('aou-org.import.clientId')}
        </Label>
        <Input
          hasErrors={Boolean(
            errors.oneRosterCredentials && errors.oneRosterCredentials.clientId,
          )}
          id="aouOrganizationDialog-oneRoster-clientId"
          type="text"
          {...register('oneRosterCredentials.clientId', {
            required: {
              value: true,
              message: t('global.required-field'),
            },
          })}
        />
        <Error
          message={errors.oneRosterCredentials?.clientId?.message}
          shouldShow={errors.oneRosterCredentials?.clientId}
        />
      </div>

      <div className="flex flex-col">
        <Label htmlFor="aouOrganizationDialog-oneRoster-clientSecret">
          {t('aou-org.import.clientSecret')}
        </Label>
        <Input
          hasErrors={Boolean(
            errors.oneRosterCredentials &&
              errors.oneRosterCredentials.clientSecret,
          )}
          id="aouOrganizationDialog-oneRoster-clientSecret"
          type="text"
          {...register('oneRosterCredentials.clientSecret', {
            required: {
              value: true,
              message: t('global.required-field'),
            },
          })}
        />
        <Error
          message={errors.oneRosterCredentials?.clientSecret?.message}
          shouldShow={errors.oneRosterCredentials?.clientSecret}
        />
      </div>
    </fieldset>
  );
}
