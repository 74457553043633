import { Error, Input, Label } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import c from 'utils/c';
import { OneRosterFields } from './OneRosterFields';
import { SmartschoolToggles } from './SmartschoolToggles';
import { dataTestIds } from '../../../../../utils/dataTestIds';

export function SmartschoolFields({
  errors,
  issuer,
  register,
  setValue,
  watch,
}) {
  const t = useFormatMessage();

  return (
    <fieldset className="border border-solid border-gray-300 p-4">
      <legend className="font-semibold text-xs p-1">{issuer}</legend>
      <div className="flex flex-col mb-4">
        <Label htmlFor="aouOrganizationDialog-smartSchoolDomain">
          {t('aou-org.smartSchoolDomain')}
        </Label>
        <div
          className={c(
            'flex rounded-md shadow-sm border border-gray-300 hover:outline hover:outline-2' +
              ' focus-within:outline focus-within:outline-2',
            !Boolean(errors.smartSchoolDomain) &&
              'hover:outline-accent-500 focus-within:outline-accent-500',
            Boolean(errors.smartSchoolDomain) &&
              'outline outline-2 outline-red-500',
          )}
        >
          <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
            https://
          </span>
          <Input
            a11yClassNames="focus:outline-none focus:border-none focus:ring-0 focus:ring-offset-0"
            a11yErrorClassNames="focus:outline-none focus:border-none focus:ring-0 focus:ring-offset-0"
            aria-describedby="aouOrganizationDialog-smartSchoolDomain-description"
            className="border-none w-full"
            data-test={dataTestIds.modal.organization.input.smartSchoolDomain}
            hasErrors={Boolean(errors.smartSchoolDomain)}
            id="aouOrganizationDialog-smartSchoolDomain"
            type="text"
            {...register('smartSchoolDomain', {
              required: {
                value: true,
                message: t('global.required-field'),
              },
              pattern: {
                value: /^[a-zA-Z0-9-]+$/,
                message: t('global.only_word_characters'),
              },
            })}
          />
          <span className="flex select-none items-center pl-3 mr-3 text-gray-500 sm:text-sm">
            .smartschool.be
          </span>
        </div>
        <p
          className="mt-2 text-sm text-gray-500"
          id="aouOrganizationDialog-smartSchoolDomain-description"
        >
          {t('aou-org.smartSchoolDomain.description')}
        </p>
        <Error
          message={errors.smartSchoolDomain?.message}
          shouldShow={errors.smartSchoolDomain}
        />
      </div>

      <OneRosterFields errors={errors} register={register} />

      <SmartschoolToggles setValue={setValue} watch={watch} />
    </fieldset>
  );
}
