import { Dropdown, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import contentTypes, { OWN_CONTENT } from 'utils/constants/contentTypes';
import { sortByWeightThenByName } from 'utils/sortByWeightThenByName';
import viewModes from 'utils/constants/viewModes';
import useFormatMessage from 'hooks/useFormatMessage';
import { listToOptions } from 'utils/listToOptions';
import useTeacherOrganizationContent from 'hooks/api/plannerService/teacher/queries/useTeacherOrganizationContent';
import { filterOperation } from 'utils/constants/filter';
import { useCallback } from 'react';
import { LoaderBars } from 'components/LoaderBars';
import { EmptyState } from '../../../components/EmptyState';
import { StudioButton } from '../../Learn/ChapterItemOverview/partials/StudioButton';

export function SelectLesson({
  formOptions,
  hidden,
  organization,
  teacherPrograms,
}) {
  const t = useFormatMessage();
  const { control, errors, setValue, watch } = formOptions;

  const { data: organizationContent } = useTeacherOrganizationContent({
    filter: [
      {
        key: 'organizationId',
        value: `${organization?.id}`,
        operation: filterOperation.EQUAL,
      },
    ],
    page: 0,
    size: 50,
  });
  const ownContent = organizationContent?.content;

  const { chapter, chapterItem, program } = watch();
  const showLessonDropdown =
    Boolean(chapter?.value) ||
    (program?.value && program.value === OWN_CONTENT);

  const findChapters = useCallback(
    (programId) =>
      teacherPrograms
        .filter((program) => program.id === programId)[0]
        ?.chapters?.sort(sortByWeightThenByName),
    [teacherPrograms],
  );

  const findChapterItems = useCallback(
    (chapterId) =>
      findChapters(program?.value)
        .filter((chapter) => chapter.id === chapterId)[0]
        ?.items?.sort(sortByWeightThenByName),
    [findChapters, program?.value],
  );

  if (hidden) return null;

  if (!teacherPrograms) {
    return <LoaderBars message={t('smartschool.popup.lessonsLoading')} />;
  }

  if (!Boolean(teacherPrograms?.length)) {
    return (
      <div>
        <EmptyState imageClass="w-32" textKey="smartschool.popup.noPrograms" />
        <div className="mt-6 text-center">
          <StudioButton notInHeader />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Label className="mt-2" htmlFor="formCalendarItem_selectProgram">
        {t('calendar.modal.selectProgram')}
      </Label>
      <div>
        <Controller
          control={control}
          name="program"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              inputId="formCalendarItem_selectProgram"
              onBlur={onBlur}
              onChange={(e) => {
                setValue('chapter', undefined);
                setValue('chapterItem', undefined);
                onChange(e);
              }}
              options={[
                {
                  value: OWN_CONTENT,
                  label: t('curricula.lessons.own'),
                },
                ...listToOptions(teacherPrograms?.sort(sortByWeightThenByName)),
              ]}
              placeholder={t('calendar.modal.selectProgram')}
              returnMethod="object"
              value={value || program}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('dropdown.no_results'),
            },
          }}
          type="select"
        />
        {errors.program && (
          <p className="text-xs text-red-600">{errors.program.message}</p>
        )}
      </div>

      {program && program.value !== OWN_CONTENT && (
        <>
          <Label className="mt-2" htmlFor="formCalendarItem_selectChapter">
            {t('calendar.modal.selectChapter')}
          </Label>
          <div>
            <Controller
              control={control}
              name="chapter"
              render={({ field: { onBlur, onChange, value } }) => (
                <Dropdown
                  inputId="formCalendarItem_selectChapter"
                  onBlur={onBlur}
                  onChange={(e) => {
                    setValue('chapterItem', undefined);
                    onChange(e);
                  }}
                  options={listToOptions(findChapters(program.value))}
                  placeholder={t('calendar.modal.selectChapter')}
                  returnMethod="object"
                  value={value || chapter}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t('dropdown.no_results'),
                },
              }}
              type="select"
            />
            {errors.chapter && (
              <p className="text-xs text-red-600">{errors.chapter.message}</p>
            )}
          </div>
        </>
      )}

      {showLessonDropdown && (
        <>
          <Label className="mt-2" htmlFor="formCalendarItem_selectChapterItem">
            {t('calendar.modal.selectChapterItem')}
          </Label>
          <Controller
            control={control}
            name="chapterItem"
            render={({ field: { onBlur, onChange, value } }) => (
              <Dropdown
                inputId="formCalendarItem_selectChapterItem"
                onBlur={onBlur}
                onChange={(e) => {
                  if (e.isExam) {
                    setValue('viewMode', viewModes.CLASSICAL);
                  }
                  onChange(e);
                }}
                options={
                  program.value === OWN_CONTENT
                    ? ownContent?.map((item) => ({
                        value: item.id,
                        label: item.title,
                        studioId: item.studioId,
                        isExam: item.type === contentTypes.EXAM,
                      }))
                    : listToOptions(findChapterItems(chapter?.value))
                }
                placeholder={t('calendar.modal.selectChapterItem')}
                returnMethod="object"
                value={value || chapterItem}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('dropdown.no_results'),
              },
            }}
            type="select"
          />
          {errors.chapterItem && (
            <p className="text-xs text-red-600">{errors.chapterItem.message}</p>
          )}
        </>
      )}
    </div>
  );
}
