export function getStudioIdForLesson(
  teacherPrograms,
  programId,
  chapterId,
  chapterItemId,
) {
  if (!teacherPrograms || !programId || !chapterId || !chapterItemId)
    return false;
  const program = teacherPrograms.find((program) => program.id === programId);
  const chapter = program.chapters.find((chapter) => chapter.id === chapterId);
  const chapterItem = chapter.items.find(
    (chapterItem) => chapterItem.id === chapterItemId,
  );

  return chapterItem?.lessons[0]?.studioId;
}
