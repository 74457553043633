import { useParams } from 'react-router-dom';
// the below is suppressed 'cause it is in the project dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Actions,
  Avatar,
  ResponsiveTableCol,
  ResponsiveTableRow,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import ProgressBar from 'components/ProgressBar';
import queryString from 'query-string';
import { EyeIcon } from '@heroicons/react/24/solid';
import { encodeQueryParams } from 'use-query-params';
import { calculateProgress } from 'utils/calculateProgress';
import { studentResultTab } from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import {
  generateStudentResultPagePath,
  generateStudentResultPageTabPath,
} from 'utils/generateUrlPaths';
import { ErrorBoundaryWrapper } from 'components/ErrorBoundaryWrapper';
import { queryParamsDefinition } from './queryParamsDefinition';

export function CalendarItemResultRow({
  amountOfOpenQuestions,
  amountOfQuestions,
  calendarItem,
  report,
  searchParams,
  student,
}) {
  const t = useFormatMessage();
  const { programId } = useParams();

  const amountOfQuestionsFallbackText = amountOfQuestions ? (
    <ProgressBar
      label={`0/${amountOfQuestions} ${t(
        'results-overview.column.progress_suffix',
      )}`}
      percent={0}
    />
  ) : (
    '-'
  );
  const amountGradedFallbackText = amountOfOpenQuestions ? (
    <ProgressBar
      label={`0/${amountOfOpenQuestions} ${t(
        'results-overview.column.graded_suffix',
      )}`}
      percent={0}
    />
  ) : (
    '-'
  );

  return (
    <ResponsiveTableRow data={student}>
      <ResponsiveTableCol>
        <ErrorBoundaryWrapper>
          <Avatar avatar={student.avatar} />
        </ErrorBoundaryWrapper>

        {student.fullName}
      </ResponsiveTableCol>
      <ResponsiveTableCol>
        <div className="flex gap-2">
          {student?.classGroups
            .filter((group) => group.archived === false)
            .map((group) => (
              <span
                key={`class${group.name}${Date.now()}`}
                className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-accent-100 text-accent-800"
              >
                {group.name}
              </span>
            ))}
        </div>
      </ResponsiveTableCol>

      {/* <ResponsiveTableCol>/!* TODO: TR-1394 *!/</ResponsiveTableCol> */}

      {/* <ResponsiveTableCol>/!* TODO: TR-1394 *!/</ResponsiveTableCol> */}

      <ResponsiveTableCol className="text-center">
        {report
          ? `${report.questionScoresEarned}/${report.questionScoresMax}`
          : '-'}
      </ResponsiveTableCol>

      <ResponsiveTableCol className="text-center">
        {report ? (
          <ProgressBar
            label={`${report.answeredQuestions}/${report.amountOfQuestions} ${t(
              'results-overview.column.progress_suffix',
            )}`}
            percent={calculateProgress(
              report.answeredQuestions,
              report.amountOfQuestions,
            )}
          />
        ) : (
          amountOfQuestionsFallbackText
        )}
      </ResponsiveTableCol>

      <ResponsiveTableCol className="text-center px-4">
        {report ? (
          <ProgressBar
            label={`${report.amountOfOpenQuestions - report.questionsToGrade}/${
              report.amountOfOpenQuestions
            } ${t('results-overview.column.graded_suffix')}`}
            percent={calculateProgress(
              report.amountOfOpenQuestions - report.questionsToGrade,
              report.amountOfOpenQuestions,
            )}
          />
        ) : (
          amountGradedFallbackText
        )}
      </ResponsiveTableCol>

      <ResponsiveTableCol
        className="px-6 py-2 whitespace-nowrap flex gap-2 justify-end"
        data-test="resultActions"
        whitespaceNoWrapOnMobile={false}
      >
        {(!!report?.scratchScoresSuccessful ||
          !!report?.scratchScoresTotal) && (
          <Actions
            items={[
              {
                icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
                label: t('global.SCRATCH_EXERCISE.view'),
                href: generateStudentResultPageTabPath(
                  calendarItem.id,
                  programId,
                  calendarItem.sessionId,
                  studentResultTab.SCRATCH_EXERCISES,
                  student.id,
                ),
                testName:
                  dataTestIds.page.learn.results.table.button.view
                    .scratchExercise,
              },
            ]}
          />
        )}
        {report && (
          <Actions
            items={[
              {
                icon: <EyeIcon className="h-4 w-4 inline-block mr-2" />,
                label: t('global.RESULT.view'),
                href: generateStudentResultPagePath(
                  calendarItem.id,
                  programId,
                  calendarItem.sessionId,
                  student.id,
                ),
                search: queryString.stringify(
                  encodeQueryParams(queryParamsDefinition(), searchParams),
                ),
                testName:
                  dataTestIds.page.learn.results.table.button.view.result,
              },
            ]}
          />
        )}
      </ResponsiveTableCol>
    </ResponsiveTableRow>
  );
}
