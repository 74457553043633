import { gql } from 'graphql-request';

export const ADMIN_UPDATE_ORGANIZATION = gql`
  mutation adminUpdateOrganization($id: Int!, $input: UpdateOrganizationInput) {
    adminUpdateOrganization(id: $id, input: $input) {
      id
      name
      prefix
      language
      issuer
      integrationInfo {
        __typename
        ... on SmartSchoolInfo {
          smartSchoolDomain
          importStudents
          importTeachers
          importClasses
          importMembershipOfStudentsToClasses
          importMembershipOfTeachersToClasses
          importOneRosterStudents
          importOneRosterTeachers
          oneRosterCredentials {
            clientId
            clientSecret
          }
        }
        ... on TeamsInfo {
          tenantId
        }
      }
      active
      beta
      demo
      demoEndDate
      steams
      showCalendar
    }
  }
`;
