import { RadioButtonList } from '@ftrprf/tailwind-components';
import { useEffect } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';
import { EmptyState } from 'components/EmptyState';
import { SelectClass } from './SelectClass';

export function SelectTarget({
  classes,
  formOptions,
  hidden,
  onTargetClick,
  setClasses,
  target,
}) {
  const t = useFormatMessage();

  const targets = [
    {
      description: t('smartschool.popup.target.all.description'),
      id: 0,
      name: t('smartschool.popup.target.all.name'),
    },
    {
      description: t('smartschool.popup.target.specific.description'),
      extra:
        target?.id === 1 ? (
          <SelectClass
            classes={classes?.content}
            formOptions={formOptions}
            setSelectedClass={setClasses}
          />
        ) : null,
      id: 1,
      name: t('smartschool.popup.target.specific.name'),
    },
  ];

  useEffect(() => {
    if (!target) onTargetClick(targets[0]);
    // disable below is because we only need to set a defaultValue on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Boolean(classes?.content?.length) ? (
    <RadioButtonList
      className={hidden ? 'hidden' : undefined}
      groupName={t('smartschool.popup.step1')}
      onClick={onTargetClick}
      selected={target}
      settings={targets}
    />
  ) : (
    <EmptyState imageClass="w-32" textKey="smartschool.popup.noClasses" />
  );
}
