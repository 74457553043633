import { gql } from 'graphql-request';

export const ADMIN_ORGANIZATION_BY_ID = gql`
  query adminOrganizationById($organizationId: Int!) {
    adminOrganizationById(organizationId: $organizationId) {
      id
      name
      prefix
      language
      issuer
      integrationInfo {
        __typename
        ... on SmartSchoolInfo {
          smartSchoolDomain
          importStudents
          importTeachers
          importClasses
          importMembershipOfStudentsToClasses
          importMembershipOfTeachersToClasses
          importOneRosterStudents
          importOneRosterTeachers
          oneRosterCredentials {
            clientId
            clientSecret
          }
        }
        ... on TeamsInfo {
          tenantId
        }
      }
      active
      stats {
        schooladmins
        students
        teachers
      }
      periods {
        id
        description
        name
        startDate
        endDate
        stats {
          classes
          programs
        }
      }
      beta
      demo
      demoEndDate
      steams
      showCalendar
      periods {
        id
        organizationId
        name
        description
        startDate
        endDate
        stats {
          classes
          programs
        }
      }
    }
  }
`;
